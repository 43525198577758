<!--
 * @Descripttion: 
 * @Date: 2023-11-12 21:54:20
 * @Author: lwh
 * @LastEditors: HUI
 * @LastEditTime: 2024-05-16 11:24:43
-->
<template>
    <div class="container_component">
      <div class="home">
          <div class="home_top">
              <div class="home_title">订单统计</div>
          </div>
          <div class="home_content">
            <div class="con_statistics df_s">
              <div class="statistics_box blue">
                <div class="statistics_text pl_15">昨天订单</div>
                <div class="statistics_text pl_15 ">{{ homeForm.yesterday_order }}</div>
              </div>
              <div class="statistics_box pink">
                <div class="statistics_text pl_15">昨天销售额</div>
                <div class="statistics_text pl_15 "> {{ homeForm.yesterday_salesVolume }} </div>
              </div>
              <div class="statistics_box red">
                <div class="statistics_text pl_15">昨天中奖金额</div>
                <div class="statistics_text pl_15 ">{{ homeForm.yesterday_bonus }}</div>
              </div>
              <div class="statistics_box green">
                <div class="statistics_text pl_15">今日单数</div>
                <div class="statistics_text pl_15 ">{{ homeForm.day_order }}</div>
              </div>
              <div class="statistics_box yellow">
                <div class="statistics_text pl_15">今日销售额</div>
                <div class="statistics_text pl_15 ">{{ homeForm.sales_volume }}</div>
              </div>
            </div>
            <div class="con_specific df">
              <div class="specific_box1 m_r_15">
                  <div class="specific_title df_s">
                      <div class="pl_15">到期时间</div>
                  </div>
                    <div class="specific_time_con">
                      <div class="specific_item df_c_s">
                        <div class="left">
                          <span class="color_red">{{ homeForm.expiration_time }}</span>
                        </div>
                        <div class="color_red">距到期还剩
                         <span class="day">{{ homeForm.expiration_days }}</span>
                          天</div>
                      </div>
                  </div>
              </div>
               <div class="specific_box2 ">
                  <div class="specific_title df_s">
                      <div class="pl_15">联系我们</div>
                  </div>
                  <div class="specific_my_con">
                    欢迎
                  </div>
              </div>
            </div>
          </div>
      </div>
      <div style="height: 370px;width: 370px;">
        <div style="width: 100%;height: 100%;" id="vs"></div>
      </div>

    </div>
  </template>
  <script>
  import Player from 'xgplayer';
  import FlvPlugin from 'xgplayer-flv'
  import flvjs from 'flv.js'
  import {getHomeForm} from "@/utils/api.js";
  export default {
    components: {
    },
      mane: 'login',
      data() {
          return {
              homeForm: {},
              videoUrl: 'http://livetx-rtmp.dongqiudi.com/live/sd-1-4101541.flv',
              input: '',
              startVideotape:false,
              muted:true
          }
      },
      mounted() {
        const player = new Player({
            id: 'vs',
            url: 'http://livetx-rtmp.dongqiudi.com/live/sd-1-4052888.flv',
            autoplay: false,
            fitVideoSize: 'auto',
            rotateFullscreen: false,
            plugins: [FlvPlugin],  // 以xgplayer的插件形式传入并挂载
        })

          
          
      //   let player = new FlvPlugin({
      //   "id": "vs",
      //   "url": "http://livetx-rtmp.dongqiudi.com/live/sd-1-4052888.flv",
      //   "playsinline": true,
      //   "whitelist": [
      //       ""
		  //   ]
      // });
      


    

        this.getHomeData()
      },
      methods: {
        player() {
        if(!this.input){
          this.$message.error('请输入播放地址地址！');
        }else{
          this.videoUrl = this.input
        }
      },
      //保存快照
      saveSnap(){
        if(!this.videoUrl){
          this.$message.error('请输入播放地址地址！');
        }else{
          this.$refs.easyPlayer.saveLocalSnapshot()
        }
      },
      //录像
      videotape(){
        if(!this.videoUrl){
          this.$message.error('请输入播放地址地址！');
        }else{
          if(this.$refs.easyPlayer.switchRecording){
            this.$refs.easyPlayer.switchRecording()
            this.startVideotape = !this.startVideotape
          }
        }
      },
      play(){
        this.$refs.easyPlayer.play()
      },
      pause(){
        this.$refs.easyPlayer.pause()
      },
      switchAudio(){
        this.$refs.easyPlayer.switchAudio()
        this.muted = !this.muted
      },
      fullScreen(){
        this.$refs.easyPlayer.fullscreen()
      },
        createVideo() {
            if (flvjs.isSupported()) {
                var videoElement = document.getElementById('videoElement');
                var flvPlayer = flvjs.createPlayer({
                    type: 'flv',
                    cors: true, // 开启跨域访问
                    url: 'http://vfx.mtime.cn/Video/2021/07/09/mp4/210709172715355157.mp4' //你的url地址
                });
                flvPlayer.attachMediaElement(videoElement);
                flvPlayer.load();
                flvPlayer.play();
            }
        },
        getHomeData() {
          getHomeForm({
            username: 'admin'
          }).then(res => {
            if(res.code == 200) {
              this.homeForm = res.data
            }
          })
        }
      }
  }
  </script>
  
  <style scoped lang="less">
    .home {
        .home_top {
          border-bottom: 1px solid #f8e9e9;
        }
        .home_title {
          font-weight: 600;
          padding: 10px 15px;
          font-size: 15px;
        }

        .home_content {
          padding: 15px;
          .con_statistics {
            width: 100%;
            font-size: 14px;
            color: #fff;
            .statistics_box {
              border-radius: 8px;
              width: 19%;
              font-weight: bold;
              .statistics_text {
                height: 45px;
                line-height: 45px;
              }
            }
          } 
        }
        .con_specific {
          margin-top: 15px;
           .specific_title {
              border-bottom: 1px solid #f6f5f7;
               height: 45px;
              line-height: 45px;
            }
             .specific_my_con {
              padding: 10px 15px;
            }
          .specific_box1 {
            width: 35%;
            border: 1px solid #f6f5f7;
            border-radius: 5px;
            .day {
              color: #4f4fec;
              font-size: 18px;
              font-weight: bold;
            }
            .specific_time_con {
              padding-bottom: 15px;
            }
            .specific_item  {
              margin-top: 10px;
              padding: 0 15px;
            }
          }

          .specific_box2{
              width: 35%;
              border: 1px solid #f6f5f7;
              border-radius: 5px;
          }
        }
    }

    .blue {
      background-color: #4788f4;
    }
    .pink{
      background-color: #9174f6;
    }
    .red {
      background-color: #f87179;
    }
    .green{
      background-color: #17cce3;
    }
    .yellow{
      background-color: #fccd74;
    }

    .color_red {
      color: #e72e5d;
    }

  </style>