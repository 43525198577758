<!--
 * @Descripttion: 篮球
 * @Date: 2023-11-30 09:57:53
 * @Author: SYC
 * @LastEditors: HUI
 * @LastEditTime: 2024-03-14 15:47:47
-->
<template>
  <div  :class="outerStyle ? 'container_component' : 'h_100'" v-loading="loading">
    <div :class="outerStyle ? 'always_box' : 'h_100' ">
      <div class="always_head">
        <headNav :navList="NavList" @selectNav="switchNavIndex"></headNav>
      </div>
      <!-- 内容表格 -->
      <div  ref="basketball"  :class="outerStyle ? 'always_content' : 'outer_content'">
        <div class="content_table mt_15">
          <div>
            <el-table :data="tableData" 
            border 
            :height="tableHedr"
            row-key="id"
            style="width: 100%"
            :expand-row-keys="expands"
            :row-class-name="getRowClass"
            @expand-change="clickRowHandle"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div
                    v-if="navIndex == 3 || navIndex == 5"
                    class="popup_score_info"
                  >
                    <div v-if="props.row.data[3].length > 0">

                      <div class="score_box df" >
                        <div class="score_title df_c m_r_15">
                          <div class="title_p">客胜</div>
                        </div>
                        <div class="score_con df">
                          <el-button
                            class="gird_item cursor"
                            :class="scoreItem1.status == 1 ? 'single_f' : ''"
                            v-for="(scoreItem1, scoreIndex) in props.row.data[3][0].data"
                            :key="scoreIndex"
                            @click="onSingle(props.row, scoreItem1, '让分客胜')"
                            @dblclick.native="shishiClick(props.row,scoreItem1)"
                            >
                            <div class="score_text">{{ scoreItem1.key }}</div>
                            <div class="score_odds">{{ scoreItem1.value }}</div>
                          </el-button>
                        </div>
                      </div>
                      <div class="score_box df">
                        <div class="score_title df_c m_r_15">
                          <div class="title_p">主胜</div>
                        </div>
                        <div class="score_con df">
                          <el-button
                            class="gird_item cursor"
                            :class="scoreItem2.status == 1 ? 'single_f' : ''"
                            v-for="(scoreItem2, scoreIndex) in props.row
                              .data[3][1].data"
                            :key="scoreIndex"
                            @click="onSingle(props.row, scoreItem2, '让分主胜')"
                            @dblclick.native="shishiClick(props.row,scoreItem2)"
                          >
                            <div class="score_text">{{ scoreItem2.key }}</div>
                            <div class="score_odds">{{ scoreItem2.value }}</div>
                          </el-button>
                        </div>
                      </div>
                    </div>
                    <div v-else class="score_started">暂未开售</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="编号" width="80">
                <template slot-scope="scope">
                  <div>{{ scope.row.week }}{{ scope.row.issue_num }}</div>
                </template>
              </el-table-column>
              <el-table-column prop="comp" label="赛事" width="150">
                <template slot-scope="scope">
                  <div class="table_game">
                    {{ scope.row.comp }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="time" label="开赛时间" width="150">
              </el-table-column>
              <el-table-column prop="home" label="客队vs主队">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.away }} <span class="vs_text">VS</span>
                    {{ scope.row.home }}
                  </div>
                </template>
              </el-table-column>
            

              <el-table-column v-if="navIndex == 1" :key="Math.random()" label="投注区">
                <el-table-column prop="name" label="主负" width="180">
                  <template slot-scope="scope">
                    <div >
                      <el-button
                        v-if=" scope.row.data[0].data[0].value != 0"
                        class="score_box_item cursor"
                        @click="
                          onSingle(scope.row, scope.row.data[0].data[0], '胜负')
                        "
                        @dblclick.native="
                          shishiClick(scope.row, scope.row.data[0].data[0])
                        "
                        :class="
                          scope.row.data[0].data[0].status == 1 ? 'single_f' : ''
                        "
                      >
                        {{ scope.row.data[0].data[0].value }}
                      </el-button>

                      <el-button
                        class="score_box_item cursor"
                        v-else
                      >
                        --
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="主胜" width="180">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                      v-if="scope.row.data[0].data[1].value != 0"
                        class="score_box_item cursor"
                        @click="
                          onSingle(scope.row, scope.row.data[0].data[1], '胜负')
                        "
                        @dblclick.native="
                          shishiClick(scope.row, scope.row.data[0].data[1])
                        "
                        :class="
                          scope.row.data[0].data[1].status == 1 ? 'single_f' : ''
                        "
                      >
                        {{ scope.row.data[0].data[1].value }}
                      </el-button>
                      <el-button
                        class="score_box_item cursor"
                        v-else
                      >
                        --
                      </el-button>
                    </div>
                    </template>
                  </el-table-column>
              </el-table-column>

              <el-table-column v-if="navIndex == 2" :key="Math.random()" label="投注区">
                <el-table-column label="让分" width="120">
                  <template slot-scope="scope">

                    <el-button
                        class="score_box_item cursor"
                        @dblclick.native="
                          updateSpread(scope.row, scope.row.data[1])
                        "
                      >
                      {{ scope.row.data[1].num }}
                      </el-button>
                  </template>
              </el-table-column>

                <el-table-column prop="name" label="主负" width="180">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        class="score_box_item cursor"
                        @click="
                          onSingle(
                            scope.row,
                            scope.row.data[1].data[0],
                            '让分胜负'
                          )
                        "
                        @dblclick.native="
                          shishiClick(scope.row, scope.row.data[1].data[0])
                        "
                        :class="
                          scope.row.data[1].data[0].status == 1 ? 'single_f' : ''
                        "
                      >
                        {{ scope.row.data[1].data[0].value }}
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="主胜" width="180">
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        class="score_box_item cursor"
                        @click="
                          onSingle(
                            scope.row,
                            scope.row.data[1].data[1],
                            '让分胜负'
                          )
                        "
                        @dblclick.native="
                          shishiClick(scope.row, scope.row.data[1].data[1])
                        "
                        :class="
                          scope.row.data[1].data[1].status == 1 ? 'single_f' : ''
                        "
                      >
                        {{ scope.row.data[1].data[1].value }}
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column v-if="navIndex == 3" :key="Math.random()" label="玩法" width="120" >
                <template slot-scope="scope">
                  <div class="score_box">
                    <el-button v-if="scope.row.expandsStatus == 0" type="primary" @click="clickRowHandle(scope.row)">展开</el-button>
                    <el-button v-else @click="clickRowHandle(scope.row)">收起</el-button>
                  </div>
                </template>
              </el-table-column>

              <el-table-column v-if="navIndex == 4"  label="投注区">
                <el-table-column  label="大" width="120">
                  <template slot-scope="scope">
                    <el-button
                      class="score_box_item cursor"
                      @click="
                        onSingle(scope.row, scope.row.data[2].data[0], '大小分')
                      "
                      @dblclick.native="
                        shishiClick(scope.row, scope.row.data[2].data[0])
                      "
                      :class="
                        scope.row.data[2].data[0].status == 1 ? 'single_f' : ''
                      "
                    >
                      {{ scope.row.data[2].data[0].value }}
                    </el-button>
                  </template>
                </el-table-column>

                <el-table-column
                label="预算总分"
                width="120"
                >
                  <template slot-scope="scope">
                      <el-button
                      class="score_box_item cursor"
                      @dblclick.native="
                        updateScore(scope.row.data[2], scope.row.data[2].score)
                      "
                    >
                      {{ scope.row.data[2].score }}
                    </el-button>
                  </template>
                </el-table-column>

                <el-table-column label="小" width="120">
                <template slot-scope="scope">
                  <el-button
                    class="score_box_item cursor"
                    @click="
                      onSingle(scope.row, scope.row.data[2].data[1], '大小分')
                    "
                    @dblclick.native="
                      shishiClick(scope.row, scope.row.data[2].data[1])
                    "
                    :class="
                      scope.row.data[2].data[1].status == 1 ? 'single_f' : ''
                    "
                  >
                    {{ scope.row.data[2].data[1].value }}
                  </el-button>
                </template>
              </el-table-column>

              </el-table-column>
              <el-table-column v-if="navIndex == 5" label="投注区">
                <el-table-column prop="name" label="胜负" width="120">
                  <template slot-scope="scope">
                    <div>
                      主负：
                      <el-button
                        v-if="scope.row.data[0].data[0].value != 0"
                        class="score_box_item cursor"
                        @click="
                          onSingle(scope.row, scope.row.data[0].data[0], '胜负')
                        "
                        @dblclick.native="
                          shishiClick(scope.row, scope.row.data[0].data[0])
                        "
                        :class="
                          scope.row.data[0].data[0].status == 1
                            ? 'single_f'
                            : ''
                        "
                      >
                        {{ scope.row.data[0].data[0].value }}
                      </el-button>
                      <el-button
                        class="score_box_item cursor"
                        v-else
                      >
                        --
                      </el-button>

                    </div>
                    <div class="mt_5">
                      主胜：
                      <el-button
                      v-if="scope.row.data[0].data[1].value != 0"
                        class="score_box_item cursor"
                        @click="
                          onSingle(scope.row, scope.row.data[0].data[1], '胜负')
                        "
                        @dblclick.native="
                          shishiClick(scope.row, scope.row.data[0].data[1])
                        "
                        :class="
                          scope.row.data[0].data[1].status == 1
                            ? 'single_f'
                            : ''
                        "
                      >
                        {{ scope.row.data[0].data[1].value }}
                      </el-button>
                      <el-button
                      class="score_box_item cursor"
                      v-else
                      >
                        --
                      </el-button>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="name" label="让分" width="180">
                  <template slot-scope="scope">
                    <div class="df_c_s">
                      <div>
                        <div>
                          主负：
                          <el-button
                            class="score_box_item cursor"
                            @click="
                              onSingle(
                                scope.row,
                                scope.row.data[1].data[0],
                                '让分胜负'
                              )
                            "
                            @dblclick.native="
                              shishiClick(scope.row, scope.row.data[1].data[0])
                            "
                            :class="
                              scope.row.data[1].data[0].status == 1
                                ? 'single_f'
                                : ''
                            "
                          >
                            {{ scope.row.data[1].data[0].value }}
                          </el-button>
                        </div>
                        <div class="mt_5">
                          主胜：
                          <el-button
                            class="score_box_item cursor"
                            @click="
                              onSingle(
                                scope.row,
                                scope.row.data[1].data[1],
                                '让分胜负'
                              )
                            "
                            @dblclick.native="
                              shishiClick(scope.row, scope.row.data[1].data[1])
                            "
                            :class="
                              scope.row.data[1].data[1].status == 1
                                ? 'single_f'
                                : ''
                            "
                          >
                            {{ scope.row.data[1].data[1].value }}
                          </el-button>
                        </div>
                      </div>
                      <div>

                        <el-button
                        class="score_box_item cursor"
                        @dblclick.native="
                          updateSpread(scope.row, scope.row.data[1])
                        "
                        >
                        {{ scope.row.data[1].num }}
                        </el-button>

                      </div>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="name" label="大小" width="180">
                  <template slot-scope="scope">
                    <div class="df_c_s">
                      <div>
                        <div>
                          大：
                          <el-button
                            class="score_box_item cursor"
                            @click="
                              onSingle(
                                scope.row,
                                scope.row.data[2].data[0],
                                '大小分'
                              )
                            "
                            @dblclick.native="
                              shishiClick(scope.row, scope.row.data[2].data[0])
                            "
                            :class="
                              scope.row.data[2].data[0].status == 1
                                ? 'single_f'
                                : ''
                            "
                          >
                            {{ scope.row.data[2].data[0].value }}
                          </el-button>
                        </div>
                        <div class="mt_5">
                          小：
                          <el-button
                            class="score_box_item cursor"
                            @click="
                              onSingle(
                                scope.row,
                                scope.row.data[2].data[1],
                                '大小分'
                              )
                            "
                            @dblclick.native="
                              shishiClick(scope.row, scope.row.data[2].data[1])
                            "
                            :class="
                              scope.row.data[2].data[1].status == 1
                                ? 'single_f'
                                : ''
                            "
                          >
                            {{ scope.row.data[2].data[1].value }}
                          </el-button>
                        </div>
                      </div>
                      <div>
                        <el-button
                          class="score_box_item cursor"
                          @dblclick.native="
                            updateScore(scope.row.data[2], scope.row.data[2].score)
                          "
                        >
                          {{ scope.row.data[2].score }}
                        </el-button>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="更多" width="120">
                  <template slot-scope="scope">
                    <div class="score_box">
                      <el-button v-if="scope.row.expandsStatus == 0" type="primary" @click="clickRowHandle(scope.row)">展开</el-button>
                      <el-button v-else @click="clickRowHandle(scope.row)">收起</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!--底部 -->
      <div class="always_bottom">
        <div class="always_bottom_title df_c_s">
          <div class="left df_c">
            <div class="mr_15">
              已选 <span class="color_red">{{ sessionNum }}</span> 场
            </div>
            <div class="multiple">
              <div class="m_r_10">倍数:</div>
              <div class="m_r_10">
                <el-input-number
                  size="mini"
                  v-model="dataNumber"
                  @change="valChange"
                  :min="1"
                  :max="50"
                  label="描述文字"
                >
                </el-input-number>
              </div>
              <div>[最高50倍]</div>
            </div>
          </div>
          <div class="right df_c">
            <div class="m_r_10">客户名称:</div>
            <div class="right_input m_r_10">
              <el-input
                size="mini"
                width="80px"
                class="m_r_10"
                v-model="customerName"
                :disabled="true"
                placeholder="请输入客户名称"
              ></el-input>
            </div>
            <div class="m_r_10">打印份数:</div>
            <div class="m_r_10">
              <el-input-number
                size="mini"
                v-model="printNum"
                :min="1"
                :max="2"
                label="描述文字"
              >
              </el-input-number>
            </div>
            <el-button size="mini" 
            :disabled="price == 0"
            @click="onSubmit" type="danger"
              >提交</el-button
            >
          </div>
        </div>
        <div class="always_bottom_way mt_15 df_s">
          <div class="left df">
            <div class="m_r_15">过关方式：</div>
            <div>
              <div class="way_radio">
                <el-radio v-model="isComplex" label="1">自由过关</el-radio>
                <el-radio
                  v-if="this.sellingNumber > 2"
                  v-model="isComplex"
                  label="2"
                  >M串N</el-radio
                >
              </div>
              <!--    自由过关 -->
              <div v-if="isComplex == 1">
                <div class="single df" v-if="singleList.length > 0">
                  <div
                    class=""
                    v-for="(singleItem, singleIndex) in singleList"
                    :key="singleIndex"
                    @click="selectMake(singleItem, singleIndex)"
                  >
                    <div
                      v-if="small <= singleIndex"
                      :class="singleItem.status == 1 ? 'select_item' : ''"
                      class="single_item m_r_15 cursor"
                    >
                      {{ singleItem.showVal }}
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <div class="single df" v-if="multipleList.length > 0">
                  <div
                    class="single_item m_r_15 cursor"
                    v-for="(multipleItem, multipleIndex) in multipleList"
                    :key="multipleIndex"
                    :class="multipleItem.status == 1 ? 'select_item' : ''"
                    @click="selectMultiple(multipleItem, multipleIndex)"
                  >
                    {{ multipleItem.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div>
              投注金额：<span class="color_red">{{ price }}</span
              >元
            </div>
            <div>
              理论最高奖金：<span class="color_red">
                <!-- <span v-if="minPrice > 0">{{ minPrice }} ～</span> -->
                {{
                  purchase_amount > 500000000 ? "5,000,000,00" : purchase_amount
                }} </span
              >元
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="修改让分"
      :visible.sync="spreadShow"
      width="380px"
      center>
        <div>
          <el-form ref="form" :model="spreadForm" label-width="80px">
            <el-form-item label="旧让分：">
              <el-input v-model="spreadForm.oldOdds"
              :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="新让分：">
              <el-input v-model="spreadForm.newOdds"></el-input>
            </el-form-item>
          </el-form>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="m_r_15" @click="spreadShow = false">取 消</el-button>
        <el-button type="primary" @click="onSpreadOdds">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改预算总分"
      :visible.sync="scoreShow"
      width="380px"
      center>
        <div>
          <el-form ref="form" :model="scoreForm" label-width="80px">
            <el-form-item label="旧总分：">
              <el-input v-model="scoreForm.oldOdds"
              :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="新总分：">
              <el-input v-model="scoreForm.newOdds"></el-input>
            </el-form-item>
          </el-form>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="m_r_15" @click="scoreShow = false">取 消</el-button>
        <el-button type="primary" @click="onScoreOdds">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改赔率"
      :visible.sync="shishiShow"
      width="380px"
      center>
        <div>
          <el-form ref="form" :model="oddsForm" label-width="80px">
            <el-form-item label="类型：">
              <div class="odds_key">{{ newOddsForm.key }}</div>
            </el-form-item>
            <el-form-item label="旧赔率：">
              <el-input v-model="oddsForm.oldOdds"
              :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="新赔率：">
              <el-input type="Number" v-model="oddsForm.newOdds"></el-input>
            </el-form-item>
          </el-form>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="m_r_15" @click="shishiShow = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateOdds">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import headNav from "@/components/navMenu";
import { 
  getRealtumeBasketball,
  getPreviousJl,
  getCodingGain,
  contestLotterys } from "@/utils/api.js";
import {
  calcDescartes,
  deepfilterArr,
  splitArray,
  getNowTime
} from "@/components/package/unit.js";
import {
  minCal,
  calculate,
  getPay,
  sortdatalist,
} from "@/components/package/opt.js";
import multipleData from "@/components/package/multipleData.js";
var time = null; //  在这里定义time 为null
export default {
  props: {
    outerStyle:  {   // false 往期数据
      type: Boolean,
      default: true
    },
    date: {
      type: String,
      default: ''
    }
  },
  components: {
    headNav,
  },
  data() {
    return {
      loading: false,
      NavList: [
        {
          name: "胜负",
          num: "1",
          gameType: "1",
        },
        {
          name: "让分胜负",
          num: "2",
          gameType: "3",
        },
        {
          name: "胜分差",
          num: "3",
          gameType: "4",
        },
        {
          name: "大小分",
          num: "4",
          gameType: "2",
        },
        {
          name: "混合过关",
          num: "5",
          gameType: "5",
        },
      ],
      gameType: 1,
      placeFraction: 0,
      selectIndex: 0,
      navIndex: 1,
      printNum: 1, // 打印的份数
      customerName: "中国体育彩票", //  客户名称
      tableData: [],
      loseList: [], // 胜平负
      scoreList: [], // 比分的数据
      sellingList: [],
      lotteryList: [], // 筛选出选中的场次
      sellingNumber: 0, // 场次
      sessionNum: 0,    // 显示出来的场次
      total: 0, // 选中的场次
      singleList: [], // 自由过关
      selectMakeList: [], // 选中的自由过关组合
      selectMultipleShow: false,
      multipleList: [], // M串N
      selectMakeList: [], // 选中M串Nde shi
      isComplex: "1", // 过关方式  1:自由过关  2.M串N
      multipleShow: true,
      dataNumber: 1, //  下单倍数
      notPerice: 2, //  订单单价，固定两元
      odds: 1, //  订单税值  北单：0.65  其他1
      optimizeList: [], //  金额数组
      note: 0, // 有多少注
      price: 0, // 金额
      minPrice: 0, // 最小金额
      purchase_amount: 0, // 最大金额
      isRemoval: false,
      minList: [],
      sendList: [],
      blendCode: 1, //1.足球 2.篮球
      target: {},
      small: 1,// 是否显示单关
      printShow: false,    // 是否显示打印
      shishiShow: false,
      maximumBonus: 0,
      oddsForm: {//更改新旧
        oldOdds: '',
        newOdds: ''
      },
      scoreForm: {
        oldOdds: '',
        newOdds: ''
      },
      scoreKey1: '',
      scoreKey2: '',
      spreadShow: false,
      spreadForm: {
        oldOdds: '',
        newOdds: ''
      },
      spreadItem: {},
      scoreItem: {},
      scoreShow: false,
      newOddsForm: {},
      expands: [],    // 要展开的行，数值的元素是row的key值
      dataId: '',
      dataKey: '',
      printForm: {},
      selectText: '',
      reachType: '',
      offsetHeight: 0,
      afterLottery: [],    // 提交的玩法数据结构
      tableHedr: ''
    };
  },
  mounted() {
    if(this.outerStyle) {
      this.getTableData();
    }else {
      this.getPeriodsForm(this.date)
    }

    this.$nextTick(() => {
     const el = this.$refs.basketball
      const height = el.offsetHeight
      this.tableHedr = height - 15
    })
  },
  
  methods: {
    getRowClass() {
      if (this.navIndex == 1 || this.navIndex == 2 || this.navIndex == 4) {
        return 'row-expand-cover'
      }else {
        return 'row-expand-code'
      }
    },
    getTableData() {
      let that = this;
      this.loading = true
      getRealtumeBasketball({}).then((res) => {
        this.loading = false
        if (res.code == 200) {
          that.tableData = res.data;
          that.tableData.forEach(tableItem => {
            that.$set(tableItem, 'expandsStatus', 0)
          })
        }
      });

      getCodingGain({
        type: 1,
        print_versions: 1
      }).then(res => {
        if(res.code == 200) {
          that.printForm = res.data
        }
      })
    },
    getPeriodsForm(date) {
      let that = this;
      this.loading = true
      getPreviousJl({
        date,
        type: 2
      }).then((res) => {
        this.loading = false
        if (res.code == 200) {
          that.tableData = res.data;
          that.tableData.forEach(tableItem => {
            that.$set(tableItem, 'expandsStatus', 0)
          })
        }
      });

      getCodingGain({
        type: 1,
        print_versions: 1
      }).then(res => {
        if(res.code == 200) {
          that.printForm = res.data
        }
      })
    },
    // 导航选择
    switchNavIndex(index) {
      this.gameType = this.NavList[Number(index) - 1].gameType
      this.navIndex = index;
      this.placeFraction = 0
      this.selectIndex =  Number(index)  - 1

      if (this.selectIndex == 4) {
        this.small = 0
        this.placeFraction = 4
      }
      // 切换类型清空所有的状态的数据
      this.cleanBaskValue()
    },
    /* 清空数据 */
    cleanBaskValue(id) {
        this.tableData.forEach(infoItem => {
          infoItem.selectNum = 0
          infoItem.data.forEach((codeItem, index) => {
            if (index == 3) {
              codeItem.forEach(item => {
                item.data.forEach(boxItem => {
                  if (boxItem.status == 1) {
                    boxItem.status = 0
                  }
                })
              })
            } else {
              if (codeItem.data && codeItem.data.length > 0) {
                  codeItem.data.forEach(codeItem => {
                  if (codeItem.status == 1) {
                    codeItem.status = 0
                  }
                })
              }
            }
          })
        })

      this.sellingNumber = 0
      this.sessionNum = 0
      this.dataNumber = 1
      this.printNum = 1
      this.target = []
      this.lotteryList = []
      this.sellingList = []
      this.purchase_amount = 0
      this.price = 0
      this.singleList = []
      this.multipleList = []
    },
    // 倍数
    async valChange(e) {
      await this.countAmount(this.notPerice, this.odds, e);
      // this.setFilteCode();
    },
    async countAmount(notPerice, odds, number) {
      this.optimizeList = [];
      let selectLen = this.selectMakeList.length;
      let newMoney = 0;
      let newNote = 0;
      let minList = [];
      await this.selectMakeList.forEach((seleItem) => {
        let count = [];
        this.minList.forEach((minItem) => {
          if (minItem.key == seleItem.value) {
            count = minItem.list;
          }
        });
        this.optimizeList.push(count);
        newNote = newNote + count.length;
        let setMoney = 0;
        let newMoneyArr = [];
        if (seleItem.name == "单关") {
          let dan = [];
          count.forEach((counts) => {
            if (this.ifType(counts)) {
              dan.push(this.maxMoney(counts));
            } else {
              dan.push([counts]);
            }
          });
          let code = 0;
          dan.forEach((item) => {
            let money = item * notPerice;
            code = money + code;
          });
          setMoney = code * number * odds;
          if (count.length > 1) {
            // 单关多场比赛需要计算最低预测奖金
            let minMoneys = [];
            count.forEach((countItem) => {
              let money = 1;
              countItem.forEach((sonItem) => {
                money = sonItem * notPerice * number * odds;
                minMoneys.push(money.toFixed(2));
              });
            });
            this.minPrice = this.minMoney(minMoneys);
          } else {
            this.minPrice = 0;
          }
        } else {
          if (count.length == 1) {
            count.forEach((counts) => {
              let money = 1;
              counts.forEach((item) => {
                money = item * money;
              });
              setMoney = money * notPerice * number * odds;
            });
          } else {
            count.forEach((counts) => {
              let card = 1;
              counts.forEach((item) => {
                card = card * item;
              });
              /* 获取最小金额 */
              let minPrice = card * notPerice * number * odds;
              minList.push(minPrice);
              setMoney = setMoney + card;
            });
            setMoney = setMoney * notPerice * number * odds;
          }
        }
        newMoney = newMoney + setMoney;
      });

      this.note = newNote; // 多少注
      this.price = this.note * this.notPerice * number; // 多少钱

      if (this.multipleShow && minList.length > 1) {
        this.minPrice = this.minMoney(minList).toFixed(2);
      }
      // 去计算奖金
      this.countBeidan(notPerice, odds, number);
    },
    /* 计算预测奖金 */
    async countBeidan(notPerice, odds, number) {
      this.isRemoval = false;
      let maximumBonus = 0; // 最大金额
      let countMaxs = [];
      const numList = [];
      this.selectMakeList.forEach((seleItem) => {
        this.sendList.forEach((sendItem) => {
          if (seleItem.value == sendItem.key) {
            let newData = {
              key: sendItem.key,
              num: sendItem.list[1],
            };
            numList.push(newData);
            countMaxs.push(sendItem.list[1]);
          }
        });
      });
      /* 统计注数和最大金额 */
      countMaxs.forEach((maxItem) => {
        // this.note += sendItem[0]
        maximumBonus += maxItem;
      });
      this.purchase_amount = (
        maximumBonus.toFixed(2) *
        notPerice *
        odds *
        number
      ).toFixed(2);
      // await this.purchaseRule(this.lotteryList.length, this.purchase_amount)
      await this.setSignList(notPerice, odds, number);
    },
    async setSignList(notPerice, odds, number) {
      let list = await this.maximum();
      let arrangeList = [];
      this.selectMakeList.forEach((seleItem) => {
        let count = this.countMoney(list, seleItem.value);
        arrangeList.push(count);
      });
      splitArray(arrangeList, notPerice, odds, number, "bask", this);
    },
    countMoney(a, min) {
      return deepfilterArr(a, min, this.isRemoval);
    },
    /* 判断类型 */
    ifType(type) {
      let flat = false;
      if (type instanceof Array) {
        flat = true;
      } else {
        flat = false;
      }
      return flat;
    },
    /* 过滤最大值 */
    maxMoney(moneyList) {
      let MaxArrp = Math.max(...moneyList);
      return MaxArrp;
    },
    /* 过滤最小值 */
    minMoney(moneyList) {
      let MinArrp = Math.min(...moneyList);
      return MinArrp;
    },
    // 选中比分  单击
    async onSingle(dataItem, item, title) {
      clearTimeout(time); //首先清除计时器
      time = setTimeout(async () => {
        if (item.status == 0) {
          let backFlag = this.isBackEnd(dataItem);
          if (this.sessionNum == 8 && !backFlag) {
            this.$message({
              type: "error",
              message: `最多只能选择8场比赛！`,
            });
            return;
          }

          this.total = this.total + 1;
          // dataItem.selectNum = dataItem.selectNum + 1
          item.status = 1;
          await this.setSelling(dataItem, item, title);
        } else {
          this.total = this.total - 1;
          // dataItem.selectNum = dataItem.selectNum - 1
          item.status = 0;
          let sellingIndex = 0;
          this.sellingList.forEach((sellingItem, index) => {
            if (
              sellingItem.value == item.value &&
              sellingItem.id == dataItem.id &&
              sellingItem.title == title
            ) {
              sellingIndex = index;
            }
          });
          this.deleteSelling(sellingIndex);
        }
      }, 300); //大概时间300ms
    },
    // 取消选中状态
    async deleteSelling(index) {
      this.sellingList.splice(index, 1);
      await this.bySelling();
      await this.childclick();
    },
    /* 统计选中的值 */
    bySelling() {
      var newArr = [];
      let obj = {};
      for (let i = 0; i < this.sellingList.length; i++) {
        //将arr[i].id作为对象属性进行判断
        if (!obj[this.sellingList[i].id]) {
          newArr.push(this.sellingList[i]);
          obj[this.sellingList[i].id] = true;
        }
      }
      this.lotteryList = newArr;
      this.sellingNumber = newArr.length;
      this.sessionNum = newArr.length;
      // this.isCommit()
    },
      /* 判断是否有条件提交 */
      isCommit() {
      let flag = false;
      if (this.sellingNumber > 0) {
        if (this.selectIndex == 0 || this.selectIndex == 4) {
          if (this.sellingNumber == 1) {
            this.purchase_amount = 0
            this.price = 0
            flag = false;
          } else {
            flag = true;
          }
        } else {
          flag = true;
        }
        if (this.sellingNumber < 2) {
          let coflag = false;
          this.sellingList.forEach((sellingItem) => {
            if (sellingItem.single == 0) {
              coflag = true;
            }
          });
          if (coflag) {
            flag = true;
            this.small = 0;
            this.multipleShow = false;
          }
        } else {
          this.small = 1;
          this.multipleShow = true;
        }
      }
      return flag;
    },
    /* 判断是否是已经选过的 */
    isBackEnd(codeItem) {
      let flag = false;
      this.sellingList.forEach((sellingItem) => {
        if (sellingItem.id == codeItem.id) {
          flag = true;
        }
      });
      return flag;
    },
    async setSelling(dataItem, item, title) {
      let num = "";
      if (
        item.key == "让胜" ||
        item.key == "让负" ||
        item.key == "让平" ||
        item.key == "让分主胜" ||
        item.key == "让分客胜"
      ) {
        num = dataItem.data[1].num;
      }
      let data = {
        id: dataItem.id, //id
        comp: dataItem.comp,
        home: dataItem.home,
        away: dataItem.away,
        short_comp: dataItem.short_comp, //赛事简称
        short_home: dataItem.short_home, //主队简称
        short_away: dataItem.short_away, //客队简称
        issue_num: dataItem.issue_num, //序号
        week: dataItem.week, //星期几
        match_time: dataItem.match_time, //比赛时间
        title: title,
        key: item.key,
        value: item.value,
        single: item.single,
        week: dataItem.week,
        name: item.name,
        code: item.code,
        rq: dataItem.rq,
        num: num, // 让夫球
      };
      this.sellingList.push(data);
      await this.bySelling();
      await this.childclick();
    },

    updateScore(dataItem,item) {
      if(this.outerStyle == true) {
        return
      }
      this.scoreShow = true;
      this.scoreForm.oldOdds = item;
      this.scoreForm.newOdds = item;
      this.scoreItem = dataItem
      this.scoreKey1 = dataItem.data[0].key
      this.scoreKey2 = dataItem.data[1].key
    },
    updateSpread(dataItem, item) {
      if(this.outerStyle == true) {
        return
      }
      this.dataId = dataItem.id
      this.spreadForm.oldOdds = item.num;
      this.spreadForm.newOdds = item.num;
      this.spreadItem = item
      this.spreadShow = true
    },
    // 修改让分
    onSpreadOdds() {
      this.spreadItem.num = this.spreadForm.newOdds
      this.spreadShow = false

      this.sellingList.forEach(sellingItem => {
        if(sellingItem.id == this.dataId && this.spreadItem.num != sellingItem.num) {
          sellingItem.num = this.spreadItem.num
        }
      })
      this.setTimeoutList()
    },
    // 修改预算总分
    onScoreOdds() {
      this.scoreItem.score = this.scoreForm.newOdds
      this.scoreItem.data.forEach(scoreItem => {
        scoreItem.key =  this.setScoreText(scoreItem.key ) + this.scoreItem.score
       
      })
      this.scoreShow = false

      this.sellingList.forEach(sellingItem => {
        if(this.scoreKey1 == sellingItem.key) {
          sellingItem.key = this.setScoreText(this.scoreKey1 ) + this.scoreItem.score
        }
        if(this.scoreKey2 == sellingItem.key) {
          sellingItem.key = this.setScoreText(this.scoreKey2) + this.scoreItem.score
        }
      })
      this.setTimeoutList()
    },

    setScoreText(text) {
      let newText = text.substring(0,2)
      return newText
    },
    // 修改赔率  双击
    shishiClick(dataItem, item) {
      if(this.outerStyle == true) {
        return
      }
      clearTimeout(time); //首先清除计时器
      this.shishiShow = true;
      this.oddsForm.oldOdds = item.value;
      this.oddsForm.newOdds = item.value;
      this.newOddsForm = item;
      this.dataId = dataItem.id
      this.dataKey = item.key
    },
     // 确定修改赔率
     async onUpdateOdds() {
      this.newOddsForm.value = this.oddsForm.newOdds
      this.shishiShow = false
      // 修改计算值的赔率   需要场次的id以及玩法的key进行判定
      this.sellingList.forEach(sellingItem => {
        if(sellingItem.id == this.dataId && this.dataKey == sellingItem.key) {
          sellingItem.value = this.oddsForm.newOdds
        }
      })
      await this.childclick(this.notPerice, this.odds, this.dataNumber)
    },
    // 统一调用算法入口
    async childclick() {
      await this.setBeidanLists();
      // 初始化多串选择

      await this.setBeidanLists()
      if (this.sellingNumber == 0) {
        this.singleList = [];
        this.purchase_amount = 0
        this.price = 0
      }

      if(!this.isCommit()) {
        return 
      }
      if(this.filterScore(this.sellingList) == false && this.sellingNumber == 1) {
        this.singleList = [];
        this.purchase_amount = 0
        this.price = 0
        return
      }
      // 是否选中比赛
      this.isComplex = "1";
      this.selectMakeList = [];
      let makeItem = {};
      this.lotteryList.sort(sortdatalist('id'))

      if(this.navIndex == 3) {
        if(this.sellingNumber > 4) {
          this.sellingNumber = 4
        }
      }


      await this.countNumber(this.sellingNumber);
      this.$nextTick(() => {
        this.singleList[this.sellingNumber - 1].status = 1;
      });
      //默认设置一个多串过关的选项
      makeItem = this.singleList[this.sellingNumber - 1];
      this.selectMakeList.push(makeItem);
      if (this.sellingNumber > 2) {
        this.setMultiple(this.sellingNumber);
      }
      await this.countMinCal();
      await this.countSingle();
      let scoreGoal = await this.filterGoal(this.sellingList);
      if (scoreGoal) {
        this.small = 0;
      } else {
        this.small = 1;
      }
      this.countAmount(this.notPerice, this.odds, this.dataNumber);
      this.setTimeoutList();
    },
    /* 清除（M串N）状态 */
    clearMultipleStatus() {
      this.multipleList.map((item) => {
        if (item.status == 1) {
          item.status = 0;
        }
      });
    },
     // 自由过关
     selectMake(item, index) {
      if (this.selectMultipleShow == true) {
        this.clearMultipleStatus();
        this.selectMakeList = [];
        this.selectMultipleShow = false;
      }
      if (item.status == 0) {
        item.status = 1;
        this.selectMakeList.push(item);
      } else {
        if (this.selectMakeList.length == 1 && item.status == 1) {
          this.$message.error("至少选择一种过关方式");
        } else {
          item.status = 0;
          this.selectMakeList.splice(index, 1);
        }
      }
      this.selectMakeList = this.singleList.filter((item) => {
        return item.status == 1;
      });
      this.countAmount(this.notPerice, this.odds, this.dataNumber);
      this.setTimeoutList();
    },
     // 筛选是否全是比分类型的
			filterScore(list) {
				let codeStu = false
				list.forEach(item => {
					if (item.title == '让分客胜' || item.title == '让分主胜') {
						codeStu = true
					}else {
						codeStu = false
          }
				})
				return codeStu
			},
     /* 计算值 */
     async setTimeoutList() {
      this.isRemoval = true;
      let arrangeList = [];
      let filteList = [];
      this.selectMakeList.forEach((seleItem) => {
        let count = minCal(this.sellingList, seleItem.value, "ang");
        arrangeList.push(count);
      });
      this.selectMakeList.forEach((seleItem) => {
        let count = minCal(this.sellingList, seleItem.value, "fil");
        filteList.push(count);
      });
      this.filteList = filteList;
      this.arrangeList = arrangeList;
      this.setFilteCode();
    },
    setFilteCode() {
      let codeList = [];
      let lotteryLen = this.lotteryList.length;
      this.filteList.forEach((arrItem, arrIndex) => {
        arrItem.forEach((codeItem, codeIndex) => {
          let data = {
            mode: lotteryLen == 1 ? "单关" : `${arrItem[0].length}串1`,
            multiple: this.dataNumber,
            bonus: this.setMoney(arrIndex, codeIndex, "filte"),
            odds: codeItem,
          };
          codeList.push(data);
        });
      });
      this.afterLottery = codeList;
    },
    /* 计算最高奖金 */
    maximum() {
      let newArr = [];
      this.lotteryList.forEach((lotterItem) => {
        // dataArr.push(item)
        let arr = this.target[lotterItem.id];
        let dataArr = [];
        arr.forEach((item) => {
          let val = item.name + "," + item.value;
          if (item.num) {
            val = val + "," + item.num;
          }
          dataArr.push(val);
        });
        newArr.push(dataArr);
      });
      return newArr;
    },
     // 计算奖金
     setMoney(arrIndex, codeIndex, type) {
      let lotteryLen = this.lotteryList.length;
      let codeNum = 0;
      if (lotteryLen > 1) {
        let money = 1;
        this.optimizeList[arrIndex].forEach((optimizeItem, index) => {
          if (index == codeIndex) {
            optimizeItem.forEach((item) => {
              money = item * money;
            });
          }
        });
        let code = "";
        if (type == "data") {
          code = money.toFixed(2) * this.notPerice;
        } else {
          code =
            money.toFixed(2) * this.notPerice * this.odds * this.dataNumber;
        }

        codeNum = code.toFixed(2);
      } else {
        codeNum = (
          this.optimizeList[0][codeIndex] *
          this.notPerice *
          this.odds *
          this.dataNumber
        ).toFixed(2);
      }
      return codeNum;
    },
    /* 判断是否开启单关 */
    filterGoal(list) {
        let codeStu = true
        list.forEach(item => {
            if (item.title == '让胜平负') {
                codeStu = false
            }
            if(item.title == '胜平负') {
                if(item.single == 1) {
                    codeStu = false
                }
            }
        })
        return codeStu
    },
    async countSingle() {
      let codeList = await this.retoolLotter();
      let calList = [];
      this.singleList.forEach((leItem) => {
        let count = calculate(codeList, leItem.value);
        let data = {
          key: leItem.value,
          list: count,
        };
        calList.push(data);
      });
      let sendList = [];
      calList.forEach((calItem) => {
        let send = getPay(calItem.list, calItem.key);
        let data = {
          key: calItem.key,
          list: send,
        };
        sendList.push(data);
      });
      this.sendList = sendList;
    },
    /* 重组数组 */
    retoolLotter() {
      let newArr = [];
      this.lotteryList.forEach((lotterItem) => {
        let formData = {
          id: lotterItem.id,
          code: lotterItem.week,
          item: lotterItem.issue_num,
          matchCode: lotterItem.week + lotterItem.issue_num,
          comp: lotterItem.comp,
          home: lotterItem.home,
          away: lotterItem.away,
          pl: [],
          plv: [],
        };
        this.sellingList.forEach((sellItem) => {
          if (lotterItem.id == sellItem.id) {
            if (this.blendCode == 1) {
              // 篮球    (问题：大小分要跟所有的组合相加)
              let sizeNum = this.filterSizeNum(sellItem);
              if (sellItem.name == "A" || sellItem.name == "H") {
                let plList = this.sizeMake(sellItem);
                if (plList.length > 0) {
                  formData.pl.push(...plList);
                } else {
                  formData.pl.push(sellItem.value);
                }
                if (sellItem.title == "让分胜负") {
                  let numText = "";
                  if (sellItem.num < 0 && sellItem.name == "A") {
                    numText = "AH";
                  } else if (sellItem.num > 0 && sellItem.name == "H") {
                    numText = "AH";
                  } else {
                    numText = sellItem.name;
                  }
                  let plList = this.spreadMake(sellItem, numText);
                  if (plList.length > 0) {
                    formData.pl.push(...plList);
                  } else {
                    formData.pl.push(sellItem.value);
                  }
                }
              } else {
                if (sellItem.title != "大小分") {
                  if (sizeNum) {
                    let text = Number(sellItem.value) + Number(sizeNum);
                    formData.pl.push(text);
                  } else {
                    formData.pl.push(sellItem.value);
                  }
                } else {
                  formData.pl.push(sellItem.value);
                }
              }
            }
          }
        });
        newArr.push(formData);
      });
      return newArr;
    },
    spreadMake(sellItem, numText) {
      let arr = this.target[sellItem.id];
      let plList = [];

      let sizeNum = this.filterSizeNum(sellItem);
      arr.forEach((codeItem) => {
        let text = "";
        if (codeItem.title == "胜负") {
          if (numText.indexOf(codeItem.name) != -1) {
            text = Number(sellItem.value) + Number(codeItem.value);
          } else {
            if (codeItem.name == numText) {
              text = Number(sellItem.value) + Number(codeItem.value);
            } else {
              text = sellItem.value;
            }
          }
          if (sizeNum) {
            text = text + Number(sizeNum);
          }
          plList.push(text);
        }
      });
      return plList;
    },
    /* 大小分 */
    sizeMake(sellItem) {
      let arr = this.target[sellItem.id];
      let plList = [];
      arr.forEach((codeItem) => {
        let text = "";
        if (codeItem.title == "大小分") {
          text = Number(sellItem.value) + Number(codeItem.value);
          plList.push(text);
        }
      });
      return plList;
    },
    /* 过滤大小分的值 */
    filterSizeNum(sellItem) {
      let arr = this.target[sellItem.id];
      let sizeList = [];
      let sizeNum = "";
      arr.filter((item) => {
        if (item.title == "大小分") {
          sizeList.push(item.value);
        }
      });

      if (sizeList.length > 0) {
        sizeNum = this.maxMoney(sizeList);
      }
      return sizeNum;
    },
    countMinCal() {
      let minList = [];
      this.singleList.forEach((leItem) => {
        let count = minCal(this.sellingList, leItem.value);
        let data = {
          key: leItem.value,
          list: count,
        };
        minList.push(data);
      });
      this.minList = minList;
    },
    /* 单串 */
    async countNumber(val) {
      this.singleList = [];
      if (val > 0) {
        for (let i = 0; i < val; i++) {
          let data = {
            name: "",
            value: 0,
            status: "",
            selectVal: ''
          };
          if (i == 0) {
            data.name = "单关";
            data.value = 1;
            data.status = 0;
            data.selectVal = `单关`
            data.showVal = `单关`
          } else {
            data.name = i + 1 + "串" + "1";
            data.value = i + 1;
            data.status = 0;
            data.selectVal = `${i + 1}x1`;
            data.showVal = `${i + 1}关`;
          }
          this.singleList.push(data);
        }
      }
      /* 计算金额 */
    },
    /* 是否有多串数据 */
    setMultiple(num) {
      this.multipleList = [];
      if (this.multipleShow) {
        let list = multipleData.multipleList;
        list.map((item) => {
          if (item.len == num) {
            this.multipleLen = item.len;
            this.multipleList = item.list;
          }
        });
      }
    },
    addMakeList(arr) {
      arr.forEach((num) => {
        this.singleList.forEach((singItem, index) => {
          if (num == index) {
            this.selectMakeList.push(singItem);
          }
        });
      });
      this.countAmount(this.notPerice, this.odds, this.dataNumber);
      this.setTimeoutList();
    },
     /* 清除自由过关的状态 */
     clearSingle() {
      this.selectMakeList = [];
      this.singleList.forEach((item) => {
        if (item.status == 1) {
          item.status = 0;
        }
      });
    },
      // 选中M串N
    selectMultiple(item, index) {
      this.clearMultipleStatus();
      item.status = 1;
      if (this.selectMakeList.length > 0) {
        this.clearSingle();
      }
      /* 更改状态 */
      if (this.selectMultipleShow == false) {
        this.selectMultipleShow = true;
      }
      this.selectText = item.selectVal;
      /* 清空数据 */
      this.selectMakeList = [];
      this.addMakeList(item.keys);
    },
    setBeidanLists() {
      let newArr = [];
      //先遍历取出搜索的key（因为要根据不同的key生成数组）
      let keys = this.sellingList.map((val) => {
        return val.id;
      });
      //数组去重，这样就得到了一共有多少个key
      keys = [...new Set(keys)];
      //声明一个target来装数组
      let target = {};
      //将target对象的每一项设成数组格式
      keys.forEach((val) => {
        target[val] = [];
      });
      //如果key值相等，则push到同一个数组
      this.sellingList.map((val) => {
        keys.some((value) => {
          if (val.id == value) {
            target[value].push(val);
            return;
          }
        });
      });
      this.target = target;
    },
    // 展开 收取
    clickRowHandle(row) {
      if (this.expands.includes(row.id)) {
        row.expandsStatus = 0
        this.expands = this.expands.filter(val => val !== row.id);
      } else {
        row.expandsStatus = 1
        this.expands.push(row.id);
      }
    },
    async onSubmit() {
      await this.setFilteCode()
      let sedFlat = false
      if(this.isComplex == 1) {
        this.reachType = '自由过关'
        sedFlat = true
        let makeTest = []
        this.selectMakeList.forEach(makeItem => {
          makeTest.push(makeItem.selectVal)
        })
        this.selectedValue = makeTest.join(',')
      }else {
        this.reachType = 'M串N'
        sedFlat = false
        this.selectedValue = this.selectText
      }
      this.setPrintData(sedFlat)
    },
    setPrintData(sedFlat) {
      const lotteryProceeds = this.price * 0.21
      // 过关方式
      const printParameter = {
        type: 2,
        game_type: this.gameType,
        random_number: this.printForm.random_number,              // 顶部编码1
        random_numberT: this.printForm.random_numberT,            // 顶部编码2
        randomString: this.printForm.randomString,                // 顶部编码3
        selectedValue: sedFlat ? this.selectedValue : '',                         // 过关方式
        multiple: this.dataNumber,                                // 倍数
        Betamount: this.price,                                    // 合计金额
        combinationboun: this.purchase_amount,            // 最高奖金
        combinationCount: this.note,           // 注数
        lotteryProceeds,                   // 公益贡献金额
        generatedNumber: this.printForm.generatedNumber,
        currentDate: getNowTime('base'),
        lottery_add: this.printForm.lottery_add,
        order_time: getNowTime(),
        print_time: getNowTime(),
        bet_money: this.price,
        customer_name: this.customerName,
        reach_type: this.reachType,
        note: this.note,
        Clearance_mode: sedFlat ? '' : this.selectedValue,
        lottery: JSON.stringify(this.sellingList),
        order_status: 1,
        after_lottery: JSON.stringify(this.afterLottery),
        sellingNumber: this.sellingNumber
      }
      // 差提交接口
      this.loading = true
      contestLotterys(printParameter).then((res) => {
      this.loading = false
        if(res.code == 200) {
            this.$message({
                type: "success",
                message: res.msg,
            });
            this.switchNavIndex(this.navIndex)
        }
      })

      getCodingGain({
        type: 1,
        print_versions: 1
      }).then(res => {
        if(res.code == 200) {
          this.printForm = res.data
        }
      })


    }
  },
};
</script>

<style scoped lang="less">
/deep/ .el-table__expanded-cell {
  padding: 0 !important;
}
@import url("../../assets/css/always.css");

.single_f {
  background: #f53629 !important;
  color: #fff !important;
}

/deep/ .el-button {
  margin-left: 0 !important;
  // border-radius: 0 !important;
}

.gird_item {
  border-radius: 0 !important;
}

.outer_content {
  overflow-y: auto;
  height: calc(100% - 212px);
}
/deep/ .el-table .row-expand-cover .cell .el-table__expand-icon {
	display: none;
  width: 0;
}

.ball_num {
  font-size: 14px;
  font-weight: bold;
}

.score_started {
  color: #FFF;
  font-weight: bold;
  font-size: 18px;
}
</style>
