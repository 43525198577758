import { render, staticRenderFns } from "./arrFive.vue?vue&type=template&id=7831d981&scoped=true"
import script from "./arrFive.vue?vue&type=script&lang=js"
export * from "./arrFive.vue?vue&type=script&lang=js"
import style0 from "./arrFive.vue?vue&type=style&index=0&id=7831d981&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7831d981",
  null
  
)

export default component.exports