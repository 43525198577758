<!--
 * @Descripttion: 排列三
 * @Date: 2023-12-02 11:23:42
 * @Author: SYC
 * @LastEditors: 靓仔辉
 * @LastEditTime: 2024-01-20 22:06:00
-->
<template>
    <div :class="outerStyle ? 'container_component' : ''"  v-loading="loading">
        <div class="always_box">
            <div >
                <div class="df_c_s p_t_10" v-if="outerStyle == true">
                    <div class="period_box">
                        当前期数：{{ nextPeriod }}
                    </div>
                    <div class="period_time">{{ expireTime }} </div>
                </div>
                <div class="df_c_s p_t_10" v-else>
                    <div class=" df_c_s">
                        <div>
                            当前期数:
                        </div>
                        <div class="select_code">
                            <el-select width="120" 
                            @change="periodChange"
                            v-model="nextPeriod" 
                            placeholder="请选择">
                                <el-option
                                v-for="item in history"
                                :key="item.issueno"
                                :label="item.issueno"
                                :value="item.issueno">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="period_time">{{ expireTime }} </div>
                </div>
                <div class="always_head">
                    <headNav
                    :navList="navList"
                    @selectNav="switchNavIndex"
                    ></headNav>
                </div>
                <div class="arrange_box">
                    <div class="arrange_content">
                        <div class="df">
                            <div class="left">
                                <div class="top_list">
                                    <el-radio-group 
                                    v-if="navIndex == 1"
                                    v-model="fixeType"
                                        @input="selectPosition"
                                    >
                                        <el-radio-button :label="straightItem.type" 
                                        v-for="(straightItem, straightIndex) in straightTopList"
                                        :key="straightIndex"
                                        
                                        >
                                            {{ straightItem.label }}
                                        </el-radio-button>
                                    </el-radio-group>
                                    <el-radio-group 
                                    v-if="navIndex == 2"
                                    v-model="fixeType"
                                        @input="selectPosition"
                                    >
                                        <el-radio-button :label="straightItem.type" 
                                        v-for="(straightItem, straightIndex) in groupTopList"
                                        :key="straightIndex"
                                        
                                        >
                                            {{ straightItem.label }}
                                        </el-radio-button>
                                    </el-radio-group>
                                </div>
                                <!-- 内容 -->
                                <div class="arrange_code mt_20">
                                    <!-- 直选 -->
                                    <div v-if="navIndex == 1">
                                        <!-- 直选定位,直选复式 -->
                                        <div v-if="fixeType == 'z-f'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第1位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(unitItem, unitIndex) in units"
                                                    @click="selectBall('z-f', unitItem, 'unit')"
                                                    :key="unitIndex"
                                                    :class="unitItem.status== 1 ? 'ball_select': ''">{{ unitItem.val }}</div>
                                                </div>
                                            </div>

                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第2位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_ul df_s">
                                                        <div class="con_li cursor"
                                                        v-for="(tenItem, tenIndex) in tens"
                                                        @click="selectBall('z-f', tenItem, 'then')"
                                                        :key="tenIndex"
                                                        :class="tenItem.status== 1 ? 'ball_select': ''">{{ tenItem.val }}</div>
                                                    </div>    
                                                </div>
                                            </div>

                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第3位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(hundredItem, hundredIndex) in hundreds"
                                                    @click="selectBall('z-f', hundredItem, 'hundred')"
                                                    :key="hundredIndex"
                                                    :class="hundredItem.status== 1 ? 'ball_select': ''">{{ hundredItem.val }}</div>
                                                </div>
                                            </div>
                                        </div>
                                         <!-- 直选定位,直选复式 -->
                                         <div v-else-if="fixeType == 'z-r'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第1位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(unitItem, unitIndex) in units"
                                                    @click="selectBall('z-r', unitItem, 'unit')"
                                                    :key="unitIndex"
                                                    :class="unitItem.status== 1 ? 'ball_select': ''">{{ unitItem.val }}</div>
                                                </div>
                                            </div>

                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第2位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_ul df_s">
                                                        <div class="con_li cursor"
                                                        v-for="(tenItem, tenIndex) in tens"
                                                        @click="selectBall('z-r', tenItem, 'then')"
                                                        :key="tenIndex"
                                                        :class="tenItem.status== 1 ? 'ball_select': ''">{{ tenItem.val }}</div>
                                                    </div>    
                                                </div>
                                            </div>

                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第3位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(hundredItem, hundredIndex) in hundreds"
                                                    @click="selectBall('z-r', hundredItem, 'hundred')"
                                                    :key="hundredIndex"
                                                    :class="hundredItem.status== 1 ? 'ball_select': ''">{{ hundredItem.val }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 直选和值 -->
                                        <div v-else-if="fixeType == 'z-v'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择和值
                                                </div>
                                                <div class="con_ul df group_width">
                                                    <div class="con_li cursor"
                                                    v-for="(groupItem, groupIndex) in groupList"
                                                    @click="selectBall('z-v', groupItem, 'valList')"
                                                    :key="groupIndex"
                                                    :class="groupItem.status== 1 ? 'ball_select': ''">{{ groupItem.val }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 跨度投注 -->
                                        <div v-else-if="fixeType == 'z-s'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择跨度
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(soabItem, soabIndex) in soabThree"
                                                    @click="selectBall('z-s', soabItem, 'valList')"
                                                    :key="soabIndex"
                                                    :class="soabItem.status== 1 ? 'ball_select': ''">{{ soabItem.val }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 组合复式 -->
                                        <div v-else-if="fixeType == 'z-c'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择号码
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(mentItem, mentIndex) in mentThree"
                                                    @click="selectBall('z-c', mentItem, 'handred')"
                                                    :key="mentIndex"
                                                    :class="mentItem.status== 1 ? 'ball_select': ''">{{ mentItem.val }}</div>
                                                </div>
                                                <div class="m_l_15 descri_text">请至少选择
                                                    <span v-if="zcRadio == 1">2</span>
                                                    <span v-if="zcRadio == 2">1</span>
                                                    <span v-if="zcRadio == 3">3</span>
                                                    个号码</div>
                                            </div>
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    
                                                </div>
                                                <div class="con_ul df">
                                                    <el-radio v-model="zcRadio" label="1">二同</el-radio>
                                                    <el-radio v-model="zcRadio" label="2">三同</el-radio>
                                                    <el-radio v-model="zcRadio" label="3">三不同</el-radio>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 组合胆拖 -->
                                        <div v-else-if="fixeType == 'z-b'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择胆码
                                                </div>
                                                <div class="con_ul df">
                                                    <div class="con_li cursor"
                                                    v-for="(gutsItem, gutsIndex) in comboGuts"
                                                    @click="selectBall('z-b', gutsItem, 'guts')"
                                                    :key="gutsIndex"
                                                    :class="gutsItem.status== 1 ? 'ball_select': ''">{{ gutsItem.val }}</div>
                                                </div>
                                                <div class="m_l_15 descri_text">请选择1个号码</div>
                                            </div>
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择拖码
                                                </div>
                                                <div class="con_ul df">
                                                    <div class="con_li cursor"
                                                    v-for="(valItem, valIndex) in comboVal"
                                                    @click="selectBall('z-b', valItem, 'valList')"
                                                    :key="valIndex"
                                                    :class="valItem.status== 1 ? 'ball_select': ''">{{ valItem.val }}</div>
                                                </div>
                                                <div class="m_l_15 descri_text">请至少选择2个，最多选择9个号码</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 组选 -->
                                    <div v-if="navIndex == 2">
                                        <!-- 直选定位,直选复式 -->
                                        <div v-if="fixeType == 'v-f'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第1位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(unitItem, unitIndex) in units"
                                                    @click="selectBall('v-f', unitItem, 'unit')"
                                                    :key="unitIndex"
                                                    :class="unitItem.status== 1 ? 'ball_select': ''">{{ unitItem.val }}</div>
                                                </div>
                                            </div>

                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第2位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_ul df_s">
                                                        <div class="con_li cursor"
                                                        v-for="(tenItem, tenIndex) in tens"
                                                        @click="selectBall('v-f', tenItem, 'then')"
                                                        :key="tenIndex"
                                                        :class="tenItem.status== 1 ? 'ball_select': ''">{{ tenItem.val }}</div>
                                                    </div>    
                                                </div>
                                            </div>
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第3位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(hundredItem, hundredIndex) in hundreds"
                                                    @click="selectBall('v-f', hundredItem, 'hundred')"
                                                    :key="hundredIndex"
                                                    :class="hundredItem.status== 1 ? 'ball_select': ''">{{ hundredItem.val }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 复式选号 -->
                                        <div v-if="fixeType == 'v-r'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择号码
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(groupItem, groupIndex) in groupThree"
                                                    @click="selectBall('v-r', groupItem, 'valList')"
                                                    :key="groupIndex"
                                                    :class="groupItem.status== 1 ? 'ball_select': ''">{{ groupItem.val }}</div>
                                                </div>
                                                <div class="m_l_15 descri_text">请至少选择2个号码</div>
                                            </div>
                                            <div class="arrange_sign df_c">
                                                <div class="description"></div>
                                                <div class="con_ul df">
                                                    <el-radio @input="setGrounpNote"  v-model="groupRadio" label="1">组三</el-radio>
                                                    <el-radio  @input="setGrounpNote" v-model="groupRadio" label="2">组六</el-radio>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 组选 和值投注 -->
                                        <div v-else-if="fixeType == 'v-v'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择和值
                                                </div>
                                                <div class="con_ul df group_and_width">
                                                    <div class="con_li cursor"
                                                    v-for="(groupItem, groupIndex) in groupAnd"
                                                    @click="selectBall('v-v', groupItem, 'valList')"
                                                    :key="groupIndex"
                                                    :class="groupItem.status== 1 ? 'ball_select': ''">{{ groupItem.val }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 跨度投注 -->
                                        <div v-else-if="fixeType == 'v-s'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择跨度
                                                </div>
                                                <div class="con_ul df_s"  v-if="groupRadio == 1">
                                                    <div class="con_li cursor"
                                                    v-for="(spanItem, spanIndex) in spanList"
                                                    @click="selectBall('v-s', spanItem, 'valList')"
                                                    :key="spanIndex"
                                                    :class="spanItem.status== 1 ? 'ball_select': ''">{{ spanItem.val }}</div>
                                                </div>
                                                <div class="con_ul df_s" v-if="groupRadio == 2">
                                                    <div class="con_li cursor"
                                                    v-for="(soabItem, soabIndex) in sixThree"
                                                    @click="selectBall('v-s', soabItem, 'valList')"
                                                    :key="soabIndex"
                                                    :class="soabItem.status== 1 ? 'ball_select': ''">{{ soabItem.val }}</div>
                                                </div>
                                            </div>
                                          
                                            <div class="arrange_sign df_c">
                                                <div class="description"></div>
                                                <div class="con_ul df">
                                                    <el-radio @input="emptyStatus"  v-model="groupRadio" label="1">组三</el-radio>
                                                    <el-radio  @input="emptyStatus" v-model="groupRadio" label="2">组六</el-radio>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 组合胆拖 -->
                                        <div v-else-if="fixeType == 'v-b'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择胆码
                                                </div>
                                                <div class="con_ul df">
                                                    <div class="con_li cursor"
                                                    v-for="(mentItem, mentIndex) in comboGuts"
                                                    @click="selectBall('v-b', mentItem, 'guts')"
                                                    :key="mentIndex"
                                                    :class="mentItem.status== 1 ? 'ball_select': ''">{{ mentItem.val }}</div>
                                                </div>
                                                <div class="m_l_15 descri_text">请选择1个号码</div>
                                            </div>

                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择拖码
                                                </div>
                                                <div class="con_ul df">
                                                    <div class="con_li cursor"
                                                    v-for="(mentItem, mentIndex) in comboVal"
                                                    @click="selectBall('v-b', mentItem, 'valList')"
                                                    :key="mentIndex"
                                                    :class="mentItem.status== 1 ? 'ball_select': ''">{{ mentItem.val }}</div>
                                                </div>
                                                <div class="m_l_15 descri_text">请至少选择2个，最多选择9个号码</div>
                                            </div>
                                            <div class="arrange_sign df_c">
                                                <div class="description"></div>
                                                <div class="con_ul df">
                                                    <el-radio @input="emptyStatus"  v-model="groupRadio" label="1">组三</el-radio>
                                                    <el-radio @input="emptyStatus" v-model="groupRadio" label="2">组六</el-radio>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- 两码全包 -->
                                        <div v-else-if="fixeType == 'v-z'">
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    选择号码
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(mentItem, mentIndex) in mentThree"
                                                    @click="selectBall('v-z', mentItem, 'handred')"
                                                    :key="mentIndex"
                                                    :class="mentItem.status== 1 ? 'ball_select': ''">{{ mentItem.val }}</div>
                                                </div>
                                                <div class="m_l_15 descri_text">请至少选择2个号码</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--  -->
                                    <div v-if="navIndex == 3">
                                        <div>
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第1位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(unitItem, unitIndex) in units"
                                                    @click="selectBall('m-v', unitItem, 'unit')"
                                                    :key="unitIndex"
                                                    :class="unitItem.status== 1 ? 'ball_select': ''">{{ unitItem.val }}</div>
                                                </div>
                                            </div>
                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第2位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_ul df_s">
                                                        <div class="con_li cursor"
                                                        v-for="(tenItem, tenIndex) in tens"
                                                        @click="selectBall('m-v', tenItem, 'then')"
                                                        :key="tenIndex"
                                                        :class="tenItem.status== 1 ? 'ball_select': ''">{{ tenItem.val }}</div>
                                                    </div>    
                                                </div>
                                            </div>

                                            <div class="arrange_sign df_c">
                                                <div class="description">
                                                    第3位
                                                </div>
                                                <div class="con_ul df_s">
                                                    <div class="con_li cursor"
                                                    v-for="(hundredItem, hundredIndex) in hundreds"
                                                    @click="selectBall('m-v', hundredItem, 'hundred')"
                                                    :key="hundredIndex"
                                                    :class="hundredItem.status== 1 ? 'ball_select': ''">{{ hundredItem.val }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right df_c" v-if="fixeType == 'z-f' || fixeType == 'v-f'">
                                <div class="add_btn">
                                    <el-button 
                                    :disabled="addButtonShow"
                                    type="primary"
                                    @click="addSelection('add')"
                                    round>添加</el-button>
                                </div>
                                <div class="m_l_15 operate_table" >
                                    <div class="table_top df">
                                        <div class="table_item table_left">已选号码</div>
                                        <div class="table_item table_right">操作</div>
                                    </div>
                                    <div class="table_con" v-if="selectionList.length > 0">
                                        <div class="con_item df" 
                                            v-for="(selectItem, selectIndex) in selectionList"
                                            :key="selectIndex"
                                            >
                                            <div class="table_item table_left df_c">
                                                <div class="select_num unit m_r_5" 
                                                v-for="(unitItem, unitIndex) in selectItem.unit"
                                                >
                                                    {{ unitItem }}
                                                </div>
                                                <div class="m_r_5">|</div>
                                                <div class="select_num then m_r_5"
                                                v-for="(thenItem, thenIndex) in selectItem.then"
                                                >
                                                    {{ thenItem }}
                                                </div>
                                                <div class="m_r_5">|</div>
                                                <div class="select_num hundred"
                                                v-for="(hundredItem, hundredIndex) in selectItem.hundred"
                                                >
                                                    {{ hundredItem }}
                                                </div>
                                            </div>
                                            <div @click="delStatusNum(selectIndex)" class="table_item table_right red cursor">删除</div>
                                        </div>
                                    </div>
                                    <div class="operate_null" v-else>
                                        暂无数据
                                    </div>
                                </div>
                            </div>
                            <div class="right df_c" v-if="fixeType == 'm-v'">
                                <div class="add_btn">
                                    <div>
                                        <el-button 
                                        :disabled="minBtn"
                                        type="primary" 
                                        @click="addMinForm('1')"
                                        round>添加直选</el-button>
                                    </div>
                                    <div class="mt_15">
                                        <el-button 
                                        :disabled="minBtn"
                                        type="success" 
                                        @click="addMinForm('2')"
                                        round>添加组选</el-button>
                                    </div>
                                </div>
                                <div class="m_l_15 min_table">
                                    <div class="table_top df" 
                                    >
                                        <div class="table_item border_right" style="width: 120px;">已选号码</div>
                                        <div class="table_item border_right" style="width: 150px;">类型</div>
                                        <div class="table_item border_right" style="width: 150px;">倍数</div>
                                        <div class="table_item table_flex">操作</div>
                                    </div>

                                    <div v-if="selectionList.length > 0" class="table_con" >
                                        <div class="con_item df"  
                                            v-for="(selectItem, selectIndex) in selectionList"
                                            :key="selectIndex">
                                            <div class="table_item df_c border_right" style="width: 120px;">
                                                <div class="select_num unit m_r_5" 
                                                v-for="(unitItem, unitIndex) in selectItem.unit"
                                                >
                                                    {{ unitItem }}
                                                </div>
                                                <div class="m_r_5">|</div>
                                                <div class="select_num then m_r_5"
                                                v-for="(thenItem, thenIndex) in selectItem.then"
                                                >
                                                    {{ thenItem }}
                                                </div>
                                                <div class="m_r_5">|</div>
                                                <div class="select_num hundred"
                                                v-for="(hundredItem, hundredIndex) in selectItem.hundred"
                                                >
                                                    {{ hundredItem }}
                                                </div>
                                            </div>
                                            <div class="table_item border_right" style="width: 150px;">
                                                <el-select v-model="selectItem.navNum" size="mini" style="width: 80px;" placeholder="请选择">
                                                    <el-option
                                                    v-for="item in minOptions"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                    </el-option>
                                                </el-select>
                                            </div>
                                            <div class="table_item border_right" style="width: 150px;">
                                                <el-input-number
                                                
                                                    style="width: 120px;"
                                                    size="mini"
                                                    v-model="selectItem.multiple"
                                                    @change="getMinNote"
                                                    :min="1"
                                                >
                                                </el-input-number>
                                            </div>
                                            <div 
                                            @click="delMinVal(selectIndex)"
                                            class="table_item table_flex red cursor">删除</div>
                                        </div>
                                    </div>
                                    <div class="operate_null" v-else>
                                        暂无数据
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>

                    <div class="arrange_descri">
                        <span v-if="fixeType == 'z-f' ||fixeType == 'v-f'">共<span class="red">{{ pour }}</span>注，共￥<span class="red">{{ amount }}</span>元</span>
                        <span v-if="fixeType == 'z-r'">
                            您选择了 {{this.zrFrom.unit.length}}个第一位， {{this.zrFrom.then.length}}个第二位，{{this.zrFrom.hundred.length}}个第三位，共 <span class="red">{{ pour }}</span> 注，共 ￥<span class="red">{{ amount }}</span> 元
                            </span>
                        <span v-if="fixeType == 'z-v'">您共选择了 {{zvFrom.valList.length}}个和值，共 <span class="red">{{ pour }}</span>注，共 ￥<span class="red">{{ amount }}</span> 元</span>
                        <span v-if="fixeType == 'z-s'">您共选择了 {{zsFrom.valList.length}}个跨度，共 <span class="red">{{ pour }}</span>注，共 ￥<span class="red">{{ amount }}</span> 元</span>
                        <span v-if="fixeType == 'z-c'">您共选择了 {{zcFrom.valList.length}}个号码，共 <span class="red">{{ pour }}</span>注，共 ￥<span class="red">{{ amount }}</span> 元</span>
                        <span v-if="fixeType == 'z-b'">您共选择了 {{zbFrom.guts.length}}个胆码，{{zbFrom.valList.length}}个拖码，共 <span class="red">{{ pour }}</span>注，共 ￥<span class="red">{{ amount }}</span> 元</span>
                        <span v-if="fixeType == 'v-r'">您共选择了 {{vrFrom.valList.length}}个号码，共 <span class="red">{{ pour }}</span>注，共 ￥<span class="red">{{ amount }}</span> 元</span>
                        <span v-if="fixeType == 'v-v'">您共选择了 {{vvFrom.valList.length}}个和值，共 <span class="red">{{ pour }}</span>注，共 ￥<span class="red">{{ amount }}</span> 元</span>
                        <span v-if="fixeType == 'v-s'">您共选择了 {{vsFrom.valList.length}}个垮度，共 <span class="red">{{ pour }}</span>注，共 ￥<span class="red">{{ amount }}</span> 元</span>
                        <span v-if="fixeType == 'v-b'">您共选择了 {{vbFrom.guts.length}}个胆码，{{vbFrom.valList.length}}个拖码，共 <span class="red">{{ pour }}</span>注，共 ￥<span class="red">{{ amount }}</span> 元</span>
                        <span v-if="fixeType == 'v-z'">您共选择了 {{vzFrom.valList.length}}个号码，共 <span class="red">{{ pour }}</span>注，共 ￥<span class="red">{{ amount }}</span> 元</span>
                        <span v-if="fixeType == 'm-v'">共<span class="red">{{ pour }}</span>注，共￥<span class="red">{{ amount }}</span>元</span>
                    </div>

                    <div class="arrange_bottom">
                        <div class="multiple">
                            <div class="m_r_10">投注倍数:</div>
                            <div class="m_r_10">
                                <el-input-number
                                    size="mini"
                                    v-model="bettingNum"
                                    @change="valChange"
                                    :min="1"
                                    :disabled="customerDisabled"
                                >
                                </el-input-number>
                            </div>
                        </div>
                        <div class="multiple">
                            <div class="m_r_10">客户名称:</div>
                            <div class="right_input m_r_10">
                                <el-input
                                    size="mini"
                                    width="80px"
                                    class="m_r_10"
                                    v-model="customerName"
                                    placeholder="请输入客户名称"
                                ></el-input>
                            </div>
                        </div>
                        <div class="multiple">
                            <div class="m_r_10">打印份数:</div>
                            <div class="m_r_10">
                                <el-input-number
                                    size="mini"
                                    v-model="printNum"
                                    @change="handleChange"
                                    :min="1"
                                    label="描述文字"
                                >
                                </el-input-number>
                            </div>
                        </div>
                        <div class="multiple">
                            <div class="m_r_10">打印时间:</div>
                            <div class="m_r_10">
                                <el-date-picker
                                    v-model="currentVal"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    >
                                </el-date-picker>
                            </div>
                        </div>
                        <el-button size="mini"
                            @click="onSubmit"
                            :disabled="pour == 0"
                            type="danger">提交</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import multipleData from "@/components/package/multipleData.js"
    import headNav from "@/components/navMenu";
    import { getThreeissue,getCodingGain,postArrangeThree } from "@/utils/api.js";   // 接口api
    import {
		newObj,
		arrayCombine,
		arrangeThree,
		arrangeGroup,
		arrangeSix,
        setInclusive,
		timeTab,
		getCurentTime,
        getNowTime
	} from '@/components/package/unit.js'
    export default {
        components: {
            headNav
        },
        props: {
            outerStyle:  {   // false 往期数据
            type: Boolean,
                default: true
            }
        },
        data() {
            return {
                printForm: {},
                nextPeriod: 0,
                expireTime: '',
                history: [],
                show: false,
                navList: [
                    {
                        name: "直选",
                        num: "1",
                        part: "111",
                    },
                    {
                        name: "组选",
                        num: "2",
                        part: "111",
                    },
                    {
                        name: "直选混合",
                        num: "3",
                        part: "111",
                    }
                ],
                navIndex: 1,
                straightTopList: [          //直选玩法
                    {
                        label: '标准选号',
                        code: 0,
                        type: 'z-f'
                    },
                    {
                        label: '复式选号',
                        code: 1,
                        type: 'z-r'
                    },
                    {
                        label: '和值投注',
                        code: 2,
                        type: 'z-v'
                    },
                    {
                        label: '跨度投注',
                        code: 3,
                        type: 'z-s'
                    },
                    {
                        label: '组合复式',
                        code: 4,
                        type: 'z-c'
                    },
                    {
                        label: '组合胆拖',
                        code: 5,
                        type: 'z-b'
                    }
                ],
                groupTopList: [          //直选玩法
                    {
                        label: '标准选号',
                        code: 0,
                        type: 'v-f'
                    },
                    {
                        label: '复式选号',
                        code: 1,
                        type: 'v-r'
                    },
                    {
                        label: '和值投注',
                        code: 2,
                        type: 'v-v'
                    },
                    {
                        label: '跨度投注',
                        code: 3,
                        type: 'v-s'
                    },
                    {
                        label: '组合胆拖',
                        code: 4,
                        type: 'v-b'
                    },
                    {
                        label: '两码全包',
                        code: 5,
                        type: 'v-z'
                    }
                ],
                groupRadio: '1',
                fixeType: 'z-f',
                printNum: 1,     // 客户名称
                bettingNum: 1,  //投注倍数
                customerName: "中国体育彩票", //  客户名称
                hundreds: [],		// 百位
				tens:[],			// 十位
				units:[],			// 个位
				mentThree: [],		// 直选复试
                spanList: [],       // 跨度投注
                sixThree: [],
                soabThree: [],      // 跨度投注
				groupList: [],  //直选和值
				groupThree: [],	// 组三
				groupSix: [],
				groupAnd: [],	// 组选和值
				doubleList: [],	// 单式重号
				oddList: [],	// 单式单号
                comboGuts: [],  // 组合胆拖 (胆)
                comboVal: [],  // 组合胆拖 (值)
                zfFrom: {			
					name: '直选定位',
					hundred:[],
					then: [],
					unit: [],
					type: 'z-f',
					odds: '',			//	注数
					money: 0,			//  金额
				},
				zrFrom: {
					name: '直选复式',
					hundred:[],
					then: [],
					unit: [],
					type: 'z-r',
					odds: '',			//	注数
					money: 0			//  金额
				},
				zvFrom: {
					name: '直选和值',
					valList: [],		// 排列值
					type: 'z-v',
					odds: '',			//	注数
					money: 0			//  金额
				},
                zsFrom: {
					name: '跨度投注',
					valList: [],		// 排列值
					type: 'z-s',
					odds: '',			//	注数
					money: 0			//  金额
				},
                zcFrom: {
					name: '组合复式',
					valList: [],		// 排列值
					type: 'z-c',
					odds: '',			//	注数
					money: 0			//  金额
				},
                zbFrom: {
					name: '组合胆拖',
					guts: [],			// 胆
					valList: [],		// 排列值
					type: 'z-b',
					odds: '',			//	注数
					money: 0			//  金额
				},
                vfFrom: {			
					name: '直选定位',
					hundred:[],
					then: [],
					unit: [],
					type: 'v-f',
					odds: '',			//	注数
					money: 0,			//  金额
				},
                vrFrom: {			
					name: '复式选号',
                    valList: [],
					type: 'v-r',
					odds: '',			//	注数
					money: 0,			//  金额
				},
                vsFrom: {
					name: '跨度投注',
					valList: [],		// 排列值
                    noteList: [],
					type: 'v-s',
					odds: '',			//	注数
					money: 0			//  金额
				},
                vtFrom: {
					name: '组选和值',
					guts: [],			// 胆
					valList: [],		// 排列值
					type: 'v-t',
					odds: '',			//	注数
					money: 0			//  金额
				},
				vvFrom: {
					name: '跨度投注',
					valList: [],		// 排列值
					type: 'v-v',
					odds: '',			//	注数
					money: 0			//  金额
				},
                vbFrom: {
					name: '组合胆拖',
					guts: [],			// 胆
					valList: [],		// 排列值
					type: 'z-b',
					odds: '',			//	注数
					money: 0			//  金额
				},
				vzFrom: {
					name: '两码全包',
					valList: [],		// 排列值
					type: 'v-z',
					odds: '',			//	注数
					money: 0,			//  金额
				},
                mvFrom: {			
					name: '直组混合',
					hundred:[],
					then: [],
					unit: [],
					type: 'm-v',
					odds: '',			//	注数
					money: 0,			//  金额
                    navNum: '1',         // 1.直选  2.组选
                    multiple: 1
				},
                minOptions: [
                    {
                        value: '1',
                        label: '直选'
                    },
                    {
                        value: '2',
                        label: '组选'
                    },
                ],
                selectionList: [],		// 选中的所有类型集合
				arrUrl: '/api/sort_three',
				stratTime : '',
				endTime : '',
				timeaStatus: false,
                addButtonShow: true,   // 添加按钮的状态
                zcRadio: '1',
                pour: 0,            // 下单注数
                amount: 0,          // 下单金额
                allNote: 0,         // 直选组合的下单注数
                allprice: 0,        // 直选组合的下单金额
                minNote: 0,
                minPrice: 0,
                pourMoney: 2,       // 单注固定2yuan
                tableData: [],
                minBtn: true,
                submitDisabled: true,
                customerDisabled: false,
                loading: false,
                currentVal: ''
            }
        },
        mounted() {
            // 初始化给各种类型的玩法赋值
            this.groupList = JSON.parse(JSON.stringify(multipleData.groupList))
			this.hundreds = JSON.parse(JSON.stringify(multipleData.ments))
			this.tens = JSON.parse(JSON.stringify(multipleData.ments))
			this.units = JSON.parse(JSON.stringify(multipleData.ments))
			this.mentThree = JSON.parse(JSON.stringify(multipleData.ments))
			// 组合胆拖 (胆)
            this.comboGuts = JSON.parse(JSON.stringify(multipleData.ments))
			//  组合胆拖 (值)
            this.comboVal = JSON.parse(JSON.stringify(multipleData.ments))
            // 复式选号

            this.spanList = JSON.parse(JSON.stringify(multipleData.spans))
            this.sixThree = JSON.parse(JSON.stringify(multipleData.spansTow))  
            
            this.groupThree = JSON.parse(JSON.stringify(multipleData.ments))
			this.groupSix = JSON.parse(JSON.stringify(multipleData.ments))
			this.groupAnd = JSON.parse(JSON.stringify(multipleData.and))
			this.oddList = JSON.parse(JSON.stringify(multipleData.ments))
            
            // 复式选号
			this.doubleList = JSON.parse(JSON.stringify(multipleData.double))
            // 跨度投注
            this.soabThree = JSON.parse(JSON.stringify(multipleData.spanform))  

            this.getArrangeNum()
        },
        methods: {
            // 查看期数
            getArrangeNum() {
                let that = this
                this.loading = true
                getThreeissue({}).then(res => {
                    that.loading = false
                  
                    that.history = res.data.history

                    if(that.outerStyle) {
                        that.nextPeriod = res.data.next_period
                        that.expireTime = res.data.expire_time
                    }else {
                        that.nextPeriod = that.history[0].issueno
                        that.expireTime =  that.history[0].expire_time
                        that.currentVal = that.dateFormat(that.history[0].expire_time)
                    }
                })

                getCodingGain({
                    type: 3,
                    print_versions: 1
                }).then(res => {
                    if(res.code == 200) {
                        that.printForm = res.data
                    }
                })
            },
            dateFormat(key) {
                let replacedStr = key.replace('年', '-').replace('月', '-').replace('日', ' ');
                const dt =  new Date(replacedStr);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + '').padStart(2, '0');
                const d = (dt.getDate() + '').padStart(2, '0');
                //时间格式年月日、时分秒
                return `${y}/${m}/${d} 00:00:00`;
            },

            getCurrentTime(key) {
                const dt =  new Date(key);
                const y = dt.getFullYear();
                const m = (dt.getMonth() + 1 + '').padStart(2, '0');
                const d = (dt.getDate() + '').padStart(2, '0');
                const hh = (dt.getHours() + '').padStart(2, '0');
                const mm = (dt.getMinutes() + '').padStart(2, '0');
                const ss = (dt.getSeconds() + '').padStart(2, '0');
                //时间格式年月日、时分秒
                //时间格式年月日、时分秒
                return `${y}/${m}/${d} ${hh}:${mm}:${ss}`;
            },

            periodChange(e) {
               let time = this.getExpireTime(e)
               console.log(time);

               this.currentVal =  this.dateFormat(time)
            },
            getExpireTime(issue) {
                let val = ''
                this.history.forEach(hisItem => {
                    if(hisItem.issueno == issue) {
                        this.expireTime = hisItem.expire_time
                        val = hisItem.expire_time
                    }
                })
                return val
            },
            selectPosition() {
                this.delStatus()
                this.addButtonShow = true
                this.pour = 0
                this.amount = 0
                this.bettingNum = 1
                this.selectionList = []
            },
            switchNavIndex(index) {
                this.navIndex = index;
                if(this.navIndex == 1) {
                    this.fixeType = 'z-f'
                    this.customerDisabled = false
                }else if(this.navIndex == 2) {
                    this.fixeType = 'v-f'
                    this.customerDisabled = false
                }else {
                    this.customerDisabled = true
                    this.fixeType = 'm-v'       // 直组混选
                }
                this.delStatus()
                this.pour = 0
                this.amount = 0
                this.bettingNum = 1
                this.selectionList = []
                this.addButtonShow = true
            },
            // 选中
            selectBall(type, item, code) {
				this.setValue(type, item, code)
				if(!this.isCondition()) {
                    this.addButtonShow = false
					this.meetcount(type)
				}else {
                    if(this.fixeType == 'z-f' || this.fixeType == 'v-f' || this.fixeType == 'm-v') {
                        return 
                    }
                    this.addButtonShow = true
                    this.pour = 0
                    this.amount = 0
				}
			},
            delStatusAll(code) {
                if(code == 'unit') {
                    this.units.forEach(unitItem => {
                        unitItem.status = 0
                    })
                }
                if(code == 'then') {
                    this.tens.forEach(tneItem => {
                        tneItem.status = 0
                    })
                }
                if(code == 'hundred') {
                    this.hundreds.forEach(hundredsItem => {
                        hundredsItem.status = 0
                    })
                }
            },
            /* 点击选中 */
			setValue( type, item, code, ) {
				if(type == 'z-f') {			// z-f 自选定位
                    this.zfFrom[code] = []
					if(item.status == 0) {
                        this.delStatusAll(code)
						item.status = 1
						this.zfFrom[code].push(item.val)
					}else {
                        this.delStatusAll(code)
						item.status = 0
						this.deleteArrIndex(this.zfFrom[code], item.val)
					}
				}else if(type == 'z-r') {	// z-r 自选复试  可设胆
					if(item.status == 0) {
						item.status = 1
						this.zrFrom[code].push(item.val)
					}else {
						item.status = 0
						this.deleteArrIndex(this.zrFrom[code], item.val)
					}
				}else if(type == 'z-v') {	// z-v 直选和值
					if(item.status == 0) {
						item.status = 1
						this.zvFrom.valList.push(item.val)
					}else {
						item.status = 0
						this.deleteArrIndex(this.zvFrom.valList, item.val)
					}
				}else if(type == 'z-s') {	// z-s 跨度投注
					if(item.status == 0) {
						item.status = 1
						this.zsFrom.valList.push(item.val)
					}else {
						item.status = 0
						this.deleteArrIndex(this.zsFrom.valList, item.val)
					}
				}else if(type == 'z-c') {	// z-c 组合复式
					if(item.status == 0) {
						item.status = 1
						this.zcFrom.valList.push(item.val)
					}else {
						item.status = 0
						this.deleteArrIndex(this.zcFrom.valList, item.val)
					}
				}else if(type == 'z-b') {	// z-b 组合胆拖
					if(item.status == 0) {
                        if(code == 'guts') {
                            if(this.zbFrom[code].length == 2) {
                                this.$message({
                                    message: '胆码个数必须小于等于2个！',
                                    type: 'warning'
                                });
                            }else {
                                item.status = 1
						        this.zbFrom[code].push(item.val)
                                // 需要判断选中的值和胆拖之间不能出现一样的值  做个判断去掉对应的状态并重新计算注数
                                this.filterComboVal('val',item.val, 'zhi')   // 过滤托码
                            }
                        }else {
                            item.status = 1
						    this.zbFrom[code].push(item.val)
                            this.filterComboVal('guts',item.val, 'zhi')   // 过滤托码
                        }
					}else {
						item.status = 0
						this.deleteArrIndex(this.zbFrom[code], item.val)
					}
				}
                else if(type == 'v-f') {			// z-f 自选定位
                    this.vfFrom[code] = []
					if(item.status == 0) {
                        this.delStatusAll(code)
						item.status = 1
						this.vfFrom[code].push(item.val)
					}else {
                        this.delStatusAll(code)
						item.status = 0
						this.deleteArrIndex(this.vfFrom[code], item.val)
					}
				} else if(type == 'v-r') {			// z-r 复式选号
                    this.vfFrom[code] = []
					if(item.status == 0) {
						item.status = 1
						this.vrFrom[code].push(item.val)
					}else {
						item.status = 0
						this.deleteArrIndex(this.vrFrom[code], item.val)
					}
				}
                else if(type == 'v-v') {	// v-v 组选 和值投注
					if(item.status == 0) {
						item.status = 1
						this.vvFrom.valList.push(item.val)
					}else {
						item.status = 0
						this.deleteArrIndex(this.vvFrom.valList, item.val)
					}
				}else if(type == 'v-s') {		// 跨度投注
					if(item.status == 0) {
						item.status = 1
						this.vsFrom.valList.push(item.val)
                        this.vsFrom.noteList.push(item)
					}else {
						item.status = 0
                        this.deleteSpanVal(item.val)
						this.deleteArrIndex(this.vsFrom.valList, item.val)
					}
				}else if(type == 'v-b') {		// 组合胆拖
					if(item.status == 0) {
                        if(code == 'guts') {
                            let flat = false
                            if(this.groupRadio == '1') {
                                if(this.vbFrom[code].length == 1) {
                                    flat = true
                                    this.$message({
                                        message: '组三胆码只能选择1个号码！',
                                        type: 'warning'
                                    });
                                }
                            }else if(this.groupRadio == '2') {
                                if(this.vbFrom[code].length == 2) {
                                    flat = true
                                    this.$message({
                                        message: '组六胆码只能选择2个号码！',
                                        type: 'warning'
                                    });
                                }
                            }
                            if(!flat) {
                                item.status = 1
                                this.vbFrom[code].push(item.val)
                                // 需要判断选中的值和胆拖之间不能出现一样的值  做个判断去掉对应的状态并重新计算注数
                                this.filterComboVal('val',item.val, 'fu')   // 过滤托码
                            }
                           
                        }else {
                            item.status = 1
						    this.vbFrom[code].push(item.val)
                            this.filterComboVal('guts',item.val, 'fu')   // 过滤托码
                        }
					}else {
						item.status = 0
						this.deleteArrIndex(this.vbFrom[code], item.val)
					}
				}else if(type == 'v-z') {	// v-z 两码全包
					if(item.status == 0) {
						item.status = 1
						this.vzFrom.valList.push(item.val)
					}else {
						item.status = 0
						this.deleteArrIndex(this.vzFrom.valList, item.val)
					}
				}else if(type == 'm-v') {
                    this.mvFrom[code] = []
					if(item.status == 0) {
                        this.delStatusAll(code)
						item.status = 1
						this.mvFrom[code].push(item.val)
					}else {
                        this.delStatusAll(code)
						item.status = 0
						this.deleteArrIndex(this.mvFrom[code], item.val)
					}
                }
			},
            deleteSpanVal(val) {
                let text = ''
                this.vsFrom.noteList.forEach(noteItem => {
                    if(noteItem.value == val) {
                        text = index
                    }
                })
                this.vsFrom.noteList.splice(text,1)
            },
            countSpanVal() {
                let noteCode = 0
                this.vsFrom.noteList.forEach(noteItem => {
                    noteCode = Number(noteItem.note)  + noteCode
                })
                return noteCode
            },
            /* 查询index */
            deleteArrIndex(array,val) {
				let text = ''
				array.forEach((item, index) => {
					if(item == val) {
						text = index
					}
				})
				array.splice(text,1)
			},
            /* 判断条件 */
			isCondition() {
				let falg = true
				if(this.fixeType == 'z-f') {
					if(this.zfFrom.hundred.length > 0 && this.zfFrom.then.length > 0 && this.zfFrom.unit.length > 0) {
						falg = false
					}
				}else if(this.fixeType == 'z-r') {
					if(this.zrFrom.hundred.length > 0 && this.zrFrom.then.length > 0 && this.zrFrom.unit.length > 0) {
						falg = false
					}
				}else if(this.fixeType == 'z-v') {
					let len = this.zvFrom.valList.length
					if(len > 0) {
						falg = false
					}
				}else if(this.fixeType == 'z-s') {
					let len = this.zsFrom.valList.length
					if(len > 0) {
						falg = false
					}
				}else if(this.fixeType == 'z-c') {
					let len = this.zcFrom.valList.length
                    if(this.zcRadio == 1) {
                        if(len > 1) {
                            falg = false
                        }
                    }else if(this.zcRadio == 2) {
                        if(len > 0) {
                            falg = false
                        }
                    }else if(this.zcRadio == 3) {
                        if(len > 2) {
                            falg = false
                        }
                    }
				}else if(this.fixeType == 'z-b') {
					let len = this.zbFrom.valList.length + this.zbFrom.guts.length
					if(len > 3) {
						falg = false
					}
				}
                if(this.fixeType == 'v-f') {
                    if(this.vfFrom.hundred.length > 0 && this.vfFrom.then.length > 0 && this.vfFrom.unit.length > 0) {
						falg = false
					}
				}
                if(this.fixeType == 'v-r') {
                    if(this.groupRadio == 1) {
                        if(this.vrFrom.valList.length > 1) {
                            falg = false
                        }
                    }else {
                        if(this.vrFrom.valList.length > 2) {
                            falg = false
                        }
                    }
				}else if(this.fixeType == 'v-v') {
					let len = this.vvFrom.valList.length
					if(len > 0) {
						falg = false
					}
				}else if(this.fixeType == 'v-s') {
					let len = this.vsFrom.valList.length
					if(len > 0) {
						falg = false
					}
				}else if(this.fixeType == 'v-b') {
                    let len = this.vbFrom.valList.length + this.vbFrom.guts.length
					if(this.groupRadio == 1) {
                        if(len > 2) {
                            falg = false
                        }
                    }else {
                        if(len > 3) {
                            falg = false
                        }
                    }
                   
				}else if(this.fixeType == 'v-z') {
					if(this.vzFrom.valList.length > 1) {
						falg = false
					}
				}else if(this.fixeType == 'm-v') {
                    if(this.mvFrom.hundred.length > 0 && this.mvFrom.then.length > 0 && this.mvFrom.unit.length > 0) {
                        this.minBtn = false
                        falg = false
					}else {
                        this.minBtn = true
                    }
                }
				return falg
			},
            emptyStatus() {
                if(this.fixeType == 'v-s') {
                    this.vsFrom.noteList = []
                    this.vsFrom.valList = []
                    this.spanList.forEach(item => {item.status = 0})
                    this.sixThree.forEach(item => {item.status = 0})
                }else if(this.fixeType == 'v-b') {
                    this.pour = 0
					this.amount = 0
                    this.vbFrom.valList = []
                    this.vbFrom.guts = []
                    this.comboGuts.forEach(item => {item.status = 0})
                    this.comboVal.forEach(item => {item.status = 0})
                }
            },
            setGrounpNote() {
                this.meetcount(this.fixeType)
            },

            /* 全局判断条件和计算下注和金额 */
			meetcount(type) {
				if(type == 'z-f') {
					let hunLen = this.zfFrom.hundred.length
					let thenLen = this.zfFrom.then.length
					let unitLen = this.zfFrom.unit.length
					this.zfFrom.odds = hunLen * thenLen * unitLen
					this.zfFrom.money = this.zfFrom.odds * this.pourMoney * this.bettingNum
				}else if(type == 'z-r') {
					let hunLen = this.zrFrom.hundred.length
					let thenLen = this.zrFrom.then.length
					let unitLen = this.zrFrom.unit.length
					this.zrFrom.odds = hunLen * thenLen * unitLen
					this.zrFrom.money = this.zrFrom.odds * this.pourMoney * this.bettingNum
					this.pour = this.zrFrom.odds
					this.amount = this.zrFrom.money
				}else if(type == 'z-v') {
					let note = 0
					this.groupList.forEach(zvItem => {
						if(zvItem.status == 1) {
							note = note + zvItem.and 
						}
					})
					this.zvFrom.odds = note
					this.zvFrom.money = note * this.pourMoney * this.bettingNum
					this.pour = this.zvFrom.odds
					this.amount = this.zvFrom.money
				}else if(type == 'z-s') {
					let note = 0
					this.soabThree.forEach(zsItem => {
						if(zsItem.status == 1) {
							note = note + zsItem.odds
						}
					})
					this.zsFrom.odds = note
					this.zsFrom.money = note * this.pourMoney * this.bettingNum
					this.pour = this.zsFrom.odds
					this.amount = this.zsFrom.money
				}else if(type == 'z-c') {
					let note = 0
                    let len = this.zcFrom.valList.length
                    if(this.zcRadio == 1) {     // 同二
                        note = this.sameTwo(len)
                    }else if(this.zcRadio == 2) {   //同三
                        note = len
                    }else if(this.zcRadio == 3) {      // 三不同
                        note = this.sameThree(len)
                    }
					this.zcFrom.odds = note
					this.zcFrom.money = note * this.pourMoney * this.bettingNum
					this.pour = this.zcFrom.odds
					this.amount = this.zcFrom.money
				}else if(type == 'z-b') {
                    let gutsLen = this.zbFrom.guts.length
					let valLen = this.zbFrom.valList.length
                    let note = this.comboGroup(gutsLen,valLen)
					this.zbFrom.odds = note
					this.zbFrom.money = note * this.pourMoney * this.bettingNum
					this.pour = this.zbFrom.odds
					this.amount = this.zbFrom.money
				}if(type == 'v-f') {
					let hunLen = this.vfFrom.hundred.length
					let thenLen = this.vfFrom.then.length
					let unitLen = this.vfFrom.unit.length
					this.vfFrom.odds = hunLen * thenLen * unitLen
					this.vfFrom.money = this.vfFrom.odds * this.pourMoney * this.bettingNum
				}
                if(type == 'v-r') {
					let valLen = this.vrFrom.valList.length
                    let note = 0
                    if(this.groupRadio == 1) {
                        note = arrangeGroup(valLen,0)
                    }else {
                        note = arrangeSix(valLen,0)
                    }
                    this.vtFrom.odds = note
					this.vtFrom.money = note * this.pourMoney * this.bettingNum
					this.pour = this.vtFrom.odds
					this.amount = this.vtFrom.money
				}else if(type == 'v-v') {
					let note = 0
					this.groupAnd.forEach(andItem => {
						if(andItem.status == 1) {
							note = note + andItem.and 
						}
					})
					this.vvFrom.odds = note
					this.vvFrom.money = note * this.pourMoney * this.bettingNum
					this.pour = this.vvFrom.odds
					this.amount = this.vvFrom.money
				}else if(type == 'v-s') {
                    let note = this.countSpanVal()
                    this.vtFrom.odds = note
					this.vtFrom.money = note * this.pourMoney * this.bettingNum
					this.pour = this.vtFrom.odds
					this.amount = this.vtFrom.money
				}else if(type == 'v-b') {
                    let gutsLen = this.vbFrom.guts.length
					let valLen = this.vbFrom.valList.length
                    let note = 0
                    if(this.groupRadio == 1) {
                        note = arrangeGroup(valLen,gutsLen)
                    }else{
                        note = arrangeSix(valLen,gutsLen)
                    }
					this.vbFrom.odds = note
					this.vbFrom.money = note * this.pourMoney * this.bettingNum
					this.pour = this.vbFrom.odds
					this.amount = this.vbFrom.money
				}else if(type == 'v-z') {
                    let valLen = this.vzFrom.valList.length
					let note = setInclusive(valLen)
                    this.vbFrom.odds = note
					this.vbFrom.money = note * this.pourMoney * this.bettingNum
					this.pour = this.vbFrom.odds
					this.amount = this.vbFrom.money
				}else if(type == 'm-v') {
                    let hunLen = this.mvFrom.hundred.length
					let thenLen = this.mvFrom.then.length
					let unitLen = this.mvFrom.unit.length
					this.mvFrom.odds = hunLen * thenLen * unitLen
					this.mvFrom.money = this.zfFrom.odds * this.pourMoney * this.bettingNum
                }
			},
            /* 加入选号 */
			async addSelection(type) {
				await this.addForm()
				this.delStatus()
                this.addButtonShow = true
                if(type == 'add') {
                    this.getArrangeNote()
                }
			},
            delMinVal(index) {
                this.selectionList.splice(index,1)
                this.getMinNote()
            },
            // 添加混合组合
            async addMinForm(type) {
                this.mvFrom.navNum = type
                await this.addForm()
                this.delStatus()
                this.addButtonShow = true
                // 统计排列的注数
                this.getMinNote()
            },
            getMinNote() {
                this.pour = this.selectionList.length
                let money = 0
                this.selectionList.forEach(item => {
                    item.money = item.multiple * this.pourMoney
                    money = item.money + money
                })
                this.amount = money
            },
            getArrangeNote() {
                let note = 0
                this.selectionList.forEach(item => {
                    note = note + Number(item.odds) 
                })
                this.pour = Math.trunc(note)
                this.amount = this.pour * this.bettingNum * this.pourMoney
            },
            addForm() {
				if(this.fixeType == 'z-f') {
					this.selectionList.push(newObj(this.zfFrom))
				}else if(this.fixeType == 'z-r') {
					this.selectionList.push(newObj(this.zrFrom))
				}else if(this.fixeType == 'z-v') {
					this.selectionList.push(newObj(this.zvFrom))
				}else if(this.fixeType == 'z-s') {
					this.selectionList.push(newObj(this.zsFrom))
				}else if(this.fixeType == 'z-c') {
					this.selectionList.push(newObj(this.zcFrom))
				}else if(this.fixeType == 'z-b') {
					this.selectionList.push(newObj(this.zbFrom))
				}else if(this.fixeType == 'v-f') {
					this.selectionList.push(newObj(this.vfFrom))
				}else if(this.fixeType == 'v-v') {
					this.selectionList.push(newObj(this.vvFrom))
				}else if(this.fixeType == 'v-v') {
					this.selectionList.push(newObj(this.vsFrom))
				}else if(this.fixeType == 'v-s') {
					this.selectionList.push(newObj(this.vsFrom))
				}else if(this.fixeType == 'v-b') {
					this.selectionList.push(newObj(this.vbFrom))
				}else if(this.fixeType == 'v-z') {
					this.selectionList.push(newObj(this.vzFrom))
				}else if(this.fixeType == 'm-v') {
					this.selectionList.push(newObj(this.mvFrom))
				}
			},
            // 删除选中的选项
            delStatusNum(index) {
                this.selectionList.splice(index,1)
                this.getArrangeNote()
            },
           
            // 清空所有的状态
			delStatus() {
				this.pour = 0
				this.amount = 0
				if(this.fixeType == 'z-f' || this.fixeType == 'z-r') {
					this.zfFrom.hundred = []
					this.zfFrom.then = []
					this.zfFrom.unit = []
                    this.zrFrom.hundred = []
					this.zrFrom.then = []
					this.zrFrom.unit = []
					this.zrFrom.odds = ''
					this.zrFrom.money = 0
					this.hundreds.forEach(item => {item.status = 0})
					this.tens.forEach(item => {item.status = 0})
					this.units.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'z-v') {
					this.zvFrom.valList = []
					this.zvFrom.odds = ''
					this.zvFrom.money = 0
					this.groupList.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'z-s') {
					this.zsFrom.valList = []
					this.zsFrom.odds = ''
					this.zsFrom.money = 0
					this.soabThree.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'z-c') {
					this.zcFrom.valList = []
					this.zcFrom.odds = ''
					this.zcFrom.money = 0
					this.mentThree.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'z-b') {
                    this.zbFrom.guts = []
					this.zbFrom.valList = []
					this.zbFrom.odds = ''
					this.zbFrom.money = 0
					this.comboGuts.forEach(item => {item.status = 0})
					this.comboVal.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'v-f') {
					this.vfFrom.guts = []
					this.vfFrom.valList = []
					this.vfFrom.odds = ''
					this.vfFrom.money = 0
                    this.hundreds.forEach(item => {item.status = 0})
					this.tens.forEach(item => {item.status = 0})
					this.units.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'v-r') {
                    this.groupRadio = '1'
					this.vrFrom.valList = []
					this.vrFrom.odds = ''
					this.vrFrom.money = 0
                    this.groupThree.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'v-v') {
					this.vvFrom.guts = []
					this.vvFrom.valList = []
					this.vvFrom.odds = ''
					this.vvFrom.money = 0
					this.groupAnd.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'v-s') {
                    this.vsFrom.noteList = []
					this.vsFrom.valList = []
					this.vsFrom.odds = ''
					this.vsFrom.money = 0
					this.spanList.forEach(item => {item.status = 0})
					this.sixThree.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'v-b') {
                    this.vbFrom.guts = []
					this.vbFrom.valList = []
					this.vbFrom.odds = ''
					this.vbFrom.money = 0
					this.comboGuts.forEach(item => {item.status = 0})
					this.comboVal.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'v-z') {
					this.vzFrom.valList = []
					this.vzFrom.odds = ''
					this.vzFrom.money = 0
                    this.mentThree.forEach(item => {item.status = 0})
				}else if(this.fixeType == 'm-v') {
                    this.minBtn = true
                    this.mvFrom.hundred = []
					this.mvFrom.then = []
					this.mvFrom.unit = []
					this.mvFrom.odds = ''
					this.mvFrom.money = 0
                    this.minNote = 0
                    this.minPrice = 0
					this.hundreds.forEach(item => {item.status = 0})
					this.tens.forEach(item => {item.status = 0})
					this.units.forEach(item => {item.status = 0})
				}
			},

            // 计算组合胆拖注数
            comboGroup(gutsLen, valLen) {
                // 1个胆码 托码至少选择3个才有注数和元子  3* (x -1)*号码个数
                // 2个胆码 托码至少选择2个才有注数和元子  选择的号码个数x6
                let note = 0
                if(gutsLen == 1) {
                    if(valLen > 2) {
                        note = 3 * (valLen - 1) * valLen
                    }
                }else if(gutsLen == 2) {
                    if(valLen > 1) {
                        note = valLen * 6
                    }
                }
                return note
            },
            sameThree(len) {
                let note = 0
                // [(n-2) × n-1)] * 号码个数
                const formula = (len - 2) * (len - 1)
                note = formula  * len
                return note
            },
            sameTwo(len) {
                let note = 0
                switch (len) {
                    case 2:
                        note = 6
                        break;
                    case 3:
                        note = 18
                        break;
                    case 4:
                        note = 36
                        break;
                    case 5:
                        note = 60
                        break;
                    case 6:
                        note = 90
                        break;
                    case 7:
                        note = 126
                        break;
                    case 8:
                        note = 168
                        break;
                    case 9:
                        note = 216
                        break;
                    case 10:
                        note = 270
                        break;
                }
                return note
            },
            filterComboVal(code, val, type) {
                if(code == 'val') {
                    let delVal = ''
                    this.comboVal.forEach((valItem, valIndex) => {
                        if(valItem.val == val) {
                            valItem.status = 0
                            delVal = val
                        }
                    })
                    if(delVal != '') {
                        if(type == 'zhi') {
                            this.zbFrom.valList = this.zbFrom.valList.filter(item => item != delVal)
                        }else {
                            this.vbFrom.valList = this.vbFrom.valList.filter(item => item != delVal)
                        }
                    }
                }else if(code == 'guts') {
                    let delVal = ''
                    this.comboGuts.forEach((valItem, valIndex) => {
                        if(valItem.val == val) {
                            valItem.status = 0
                            delVal = val
                        }
                    })
                    if(delVal != '') {
                        if(type == 'zhi') {
                            this.zbFrom.guts = this.zbFrom.guts.filter(item => item != delVal)
                        }else {
                            this.vbFrom.guts = this.vbFrom.guts.filter(item => item != delVal)
                        }
                    }
                }
            },
            minChange() {
                this.getMinNote()
            },
            // 投注倍数
            valChange(e) {
                this.meetcount(this.fixeType)

                if(this.fixeType == 'z-f' || this.fixeType == 'v-f') {
                    this.getArrangeNote()
                }
            },
            // 打印份数
            handleChange() {

            },
            addSelectData() {
                if(this.fixeType == 'z-r') {
					this.selectionList.push(newObj(this.zrFrom))
				}else if(this.fixeType == 'z-v') {
					this.selectionList.push(newObj(this.zvFrom))
				}else if(this.fixeType == 'z-s') {
					this.selectionList.push(newObj(this.zsFrom))
				}else if(this.fixeType == 'z-c') {
					this.selectionList.push(newObj(this.zcFrom))
				}else if(this.fixeType == 'z-b') {
					this.selectionList.push(newObj(this.zbFrom))
				}else if(this.fixeType == 'v-v') {
					this.selectionList.push(newObj(this.vvFrom))
				}else if(this.fixeType == 'v-r') {
					this.selectionList.push(newObj(this.vrFrom))
				}else if(this.fixeType == 'v-s') {
					this.selectionList.push(newObj(this.vsFrom))
				}else if(this.fixeType == 'v-b') {
					this.selectionList.push(newObj(this.vbFrom))
				}else if(this.fixeType == 'v-z') {
					this.selectionList.push(newObj(this.vzFrom))
				}
            },
            // 提交
            async onSubmit() {
                await this.addSelectData()
                let proceeds = (this.amount * 0.34).toFixed(2)
                console.log(this.selectionList);

                // 提交订单
                // 提交参数
                let data = {
                    navType: this.navIndex,             // 排列三类型 1. 直选 2.组选 3.直选混合
                    type: this.fixeType,                // 选择类型
                    zcRadio: this.zcRadio,
                    groupRadio: this.groupRadio,          
                    issue: this.nextPeriod,           // 期数
                    random_number: this.printForm.random_number,
                    random_numberT: this.printForm.random_numberT,
                    random_numberS: this.printForm.random_numberS,
                    random_numberF: this.printForm.random_numberF,
                    random_numbeV: this.printForm.random_numbeV,
                    randomString: this.printForm.randomString,
                    multiple: this.bettingNum,                        // 倍数
                    bet_money: this.amount,              // 合计金额
                    lottery: JSON.stringify(this.selectionList),                            // 选择的参数数组
                    lotteryProceeds: proceeds,                    // 公益贡献金额
                    random_numbeE: this.printForm.random_numbeE,
                    currentDate: this.currentVal =='' ? getNowTime('base') : this.getCurrentTime(this.currentVal),                                  // 打印时间
                    lottery_add: this.printForm.lottery_add,
                    expire_time: this.expireTime,                        // 开奖时间
                    order_time: getNowTime(),
                    print_time: getNowTime(),
                    customer_name: this.customerName,
                    note: this.pour
                }
                console.log(data);
                this.loading = true
                postArrangeThree(data).then(res => {
                    this.loading = false
                    if(res.code == 200) {
                        this.$message({
                            type: "success",
                            message: res.msg,
                        });
                        this.switchNavIndex(this.navIndex)
                    }
                })

                getCodingGain({
                    type: 3,
                    print_versions: 1
                }).then(res => {
                    if(res.code == 200) {
                        this.printForm = res.data
                    }
                })
            }
        },
        watch: {
            zcRadio() {
                this.delStatus()
            }
        }
    }
</script>

<style scoped lang="less">
@import url("../../assets/css/always.css");

.single_f {
  background: #f53629 !important;
  color: #fff !important;
}
.arrange_box {
    padding: 20px 35px;
    .arrange_content {
        padding-bottom: 15px;
        border-bottom: 1px solid #e6e6e6 ;
        .left{

        }
        .right{
            margin-left: 55px;
        }
        .arrange_code {
            .arrange_sign {
                font-size: 14px;
                margin-bottom: 15px;
                .description {
                    text-align: center;
                    width: 90px;
                    color: #8d8585;
                }
                .con_ul {
                    .con_li {
                        margin-left: 8px;
                        width: 35px;
                        height: 35px;
                        line-height: 35px;
                        text-align: center;
                        color: #000;
                        font-weight: bold;
                        border-radius: 50%;
                        border: 1px solid #e6e6e6;
                    }
                    .ball_select {
                        background-color: #409EFF;
                        color: #fff;
                    }
                }
            }
        }
    }
    .descri_text {
        color: #8d7f7f;
    }
    // 选中的描述
    .arrange_descri {
        color: #8d7f7f;
        padding: 15px 0;
        font-size: 15px; 
    }
    // 底部
    .arrange_bottom {
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding: 15px 0;
    }
}

.group_width {
    flex-wrap: wrap;
    width: 640px;
    .con_li {
        margin-bottom: 10px;
    }
}

.group_and_width {
    flex-wrap: wrap;
    width: 580px;
    .con_li {
        margin-bottom: 10px;
    }
}

.table_top {
    font-weight: bold;
    border-bottom: 1px solid #e6e6e6;
}
.table_item {
    height: 35px;
    line-height: 35px;
    padding-left: 10px;
}

.table_flex {
    flex: 1
}

.border_right {
    border-right: 1px solid #e6e6e6;
}

.table_con {
    height: 165px;
    overflow-y: auto;
    color: #978686;
    .con_item {
        border-bottom: 1px solid #e6e6e6;
    }
}

.select_num {
    font-size: 15px;
    color: #000;
    font-weight: 500;
}
.operate_table {
    width: 380px;
    height: 200px;
    border: 1px solid #e6e6e6;
    color: #000;
    overflow: hidden;
    .table_left {
        width: 250px;
        border-right: 1px solid #e6e6e6;
    }
    .table_right {
        flex: 1;
    }
}

.min_table {
    width: 500px;
    height: 200px;
    border: 1px solid #e6e6e6;
    color: #000;
    overflow: hidden;
}

.operate_null {
    text-align: center;
    color: #8d8282;
    line-height: 150px;
}

.red {
    color: #f53629;
}

.select_code {
  width: 120px;
  margin-left: 20px;
}

</style>