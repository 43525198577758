<!--
 * @Descripttion: 北京单场
 * @Date: 2023-12-01 13:55:14
 * @Author: SYC
 * @LastEditors: HUI
 * @LastEditTime: 2024-04-12 09:47:46
-->
<template>
  <div :class="outerStyle ? 'container_component' : 'h_100'" v-loading="loading">
    <div :class="outerStyle ? 'always_box' : 'h_100' ">
        <div class="always_head">
            <headNav :navList="NavList" @selectNav="switchNavIndex"></headNav>
        </div>
        <div class="beidan_content">
          <div class="p_t_10" v-if="outerStyle == true">
            <div class="period_box">当前期数：{{ bonusPeriod }}</div>
          </div>
          <div class="p_t_10 df_c_s" v-else>
            <div class="df_c_s">
              <div>
                当前期数:
              </div>
              <div class="select_code df_c_s">
                  <el-select width="120" 
                  @change="yearChange"
                  v-model="year" 
                  placeholder="请选择">
                      <el-option
                      v-for="item in yearList"
                      :key="item.val"
                      :label="item.val"
                      :value="item.val">
                      </el-option>
                  </el-select>
                  <div class="m_l_15">年</div>
              </div>
              <div class="select_code df_c_s">
                  <el-select width="120" 
                  @change="monthChange"
                  v-model="month" 
                  placeholder="请选择">
                      <el-option
                      v-for="item in monthList"
                      :key="item.val"
                      :label="item.label"
                      :value="item.val">
                      </el-option>
                  </el-select>
                  <div class="m_l_15">月</div>
              </div>

              <div class="select_code df_c_s">
                  <el-select width="120" 
                  @change="periodChange"
                  v-model="bonusPeriod" 
                  placeholder="请选择">
                      <el-option
                      v-for="item in periodsList"
                      :key="item"
                      :label="item"
                      :value="item">
                      </el-option>
                  </el-select>
                  <div class="m_l_15">期</div>
              </div>
              
            </div>
          </div>


          <div class="time_nave" v-if="navIndex != 6">
              <el-menu 
                  :default-active="handNav" 
                  class="el-menu-demo" 
                  mode="horizontal"
                  active-text-color="#1868ca"
                  @select="handleSelect"
              >
                  <el-menu-item 
                      :index="dataIndex.toString()" 
                      v-for="(navItem, dataIndex) in beidanList"
                      :key="dataIndex"
                  >{{navItem.title}}</el-menu-item>
              </el-menu>
          </div>
          
          <div class="time_nave" v-else>
              <el-menu 
                  :default-active="handNav" 
                  class="el-menu-demo" 
                  mode="horizontal"
                  active-text-color="#1868ca"
                  @select="handleSelect"
              >
                  <el-menu-item 
                      :index="dataIndex.toString()" 
                      v-for="(navItem, dataIndex) in bifenList"
                      :key="dataIndex"
                  >{{navItem.title}}</el-menu-item>
              </el-menu>
          </div>
          <div ref="beidan"  class="content_table mt_15" >
              <div v-show="navIndex != 6">
                  <el-table
                  :data="tableData" 
                  border 
                  :height="tableHedr"
                  style="width: 100%"
                  row-key="id"
                  :row-class-name="getRowClass"
                  :expand-row-keys="expands"
                  @expand-change="clickRowHandle"
                  >
                      <el-table-column type="expand"  >
                          <template slot-scope="props">
                          <!-- 比分 -->
                              <div  class="popup_score_info" v-if="navIndex == 2">
                                  <div class="score_box df">
                                  <div class="score_title df_c m_r_15">
                                      <div class="title_p">胜</div>
                                  </div>
                                  <div class="score_con df ">
                                      <el-button
                                      class="gird_item cursor"
                                      :class="scoreItem.status == 1 ? 'single_f' : ''"
                                      v-for="(scoreItem, scoreIndex) in props.row.odds[4].data[0].data"
                                      :key="scoreIndex"
                                      @click="onSingle(props.row, scoreItem, '比分')"
                                      @dblclick.native="shishiClick(props.row, scoreItem)"
                                      >
                                          <div class="score_text">{{ scoreItem.key }}</div>
                                          <div class="score_odds">{{ scoreItem.value }}</div>
                                      </el-button>
                                      <el-button
                                      class="gird_item cursor "
                                      @dblclick.native="shishiClick( props.row,props.row.odds[4].data[1].data[0],)"
                                      @click="
                                          onSingle(
                                          props.row,
                                          props.row.odds[4].data[1].data[0],
                                          '比分'
                                          )
                                      "
                                      :class="
                                          props.row.odds[4].data[1].data[0].status == 1
                                          ? 'single_f'
                                          : ''
                                      "
                                      >
                                      <div class="score_text">
                                          {{ props.row.odds[4].data[1].data[0].key }}
                                      </div>
                                      <div class="score_odds">
                                          {{ props.row.odds[4].data[1].data[0].value }}
                                      </div>
                                      </el-button>
                                  </div>
                                  </div>
                                  <div class="score_box df">
                                  <div class="score_title df_c m_r_15">
                                      <div class="title_p">平</div>
                                  </div>
                                  <div class="score_con df ">
                                      <el-button
                                      class="gird_item cursor"
                                      v-for="(scoreItem2, scoreIndex) in props.row.odds[4]
                                          .data[2].data"
                                      @click="onSingle(props.row, scoreItem2, '比分')"
                                      @dblclick.native="shishiClick(props.row, scoreItem2)"
                                      :key="scoreIndex"
                                      :class="scoreItem2.status == 1 ? 'single_f' : ''"
                                      >
                                        <div class="score_text">{{ scoreItem2.key }}</div>
                                        <div class="score_odds">{{ scoreItem2.value }}</div>
                                      </el-button>
                                      <el-button
                                      class="gird_item cursor"
                                      :class="
                                          props.row.odds[4].data[3].status == 1
                                          ? 'single_f'
                                          : ''
                                      "
                                        @dblclick.native="shishiClick(props.row, props.row.odds[4].data[3].data[0])"
                                      @click="
                                          onSingle(
                                          props.row,
                                          props.row.odds[4].data[3].data[0],
                                          '比分'
                                          )
                                      "
                                      >
                                        <div class="score_text">
                                            {{ props.row.odds[4].data[3].data[0].key }}
                                        </div>
                                        <div class="score_odds">
                                            {{ props.row.odds[4].data[3].data[0].value }}
                                        </div>
                                      </el-button>
                                  </div>
                                  </div>
                                  <div class="score_box df">
                                  <div class="score_title df_c m_r_15">
                                      <div class="title_p">负</div>
                                  </div>
                                  <div class="score_con df ">
                                      <el-button
                                      class="gird_item cursor"
                                      v-for="(scoreItem3, scoreIndex) in props.row.odds[4]
                                          .data[4].data"
                                      @dblclick.native="shishiClick(props.row, scoreItem3)"
                                      @click="onSingle(props.row, scoreItem3, '比分')"
                                      :key="scoreIndex"
                                      :class="scoreItem3.status == 1 ? 'single_f' : ''"
                                      >
                                        <div class="score_text">{{ scoreItem3.key }}</div>
                                        <div class="score_odds">{{ scoreItem3.value }}</div>
                                      </el-button>
                                      <el-button
                                      class="gird_item cursor"
                                      @dblclick.native="shishiClick(props.row,  props.row.odds[4].data[5].data[0])"
                                      @click="
                                          onSingle(
                                          props.row,
                                          props.row.odds[4].data[5].data[0],
                                          '比分'
                                          )
                                      "
                                      :class="
                                          props.row.odds[4].data[5].data[0].status == 1
                                          ? 'single_f'
                                          : ''
                                      "
                                      >
                                        <div class="score_text">
                                            {{ props.row.odds[4].data[5].data[0].key }}
                                        </div>
                                        <div class="score_odds">
                                            {{ props.row.odds[4].data[5].data[0].value }}
                                        </div>
                                      </el-button>
                                  </div>
                                  </div>
                              </div>
                          </template>
                      </el-table-column>
                      <el-table-column
                          label="场"
                          type="index"
                          width="50">
                          <template slot-scope="scope">
                              <div>{{scope.row.id_str}}</div>
                          </template>
                          
                      </el-table-column>
                      <el-table-column label="类型" width="80">
                          <template>
                              <div>足球</div>
                          </template>
                      </el-table-column>
                      <el-table-column label="状态" width="80">
                          <template>
                              <div>销售中</div>
                          </template>
                      </el-table-column>
                      <el-table-column label="赛事" width="160">
                          <template slot-scope="scope">
                              <div>{{ scope.row.comp }}</div>
                          </template>
                      </el-table-column>
                      <el-table-column label="时间" width="80">
                          <template slot-scope="scope">
                              <div>{{ scope.row.time }}</div>
                          </template>
                      </el-table-column>
                      <el-table-column prop="home" label="主队vs客队">
                          <template slot-scope="scope">
                          <div>
                              {{ scope.row.home }} <span class="vs_text">VS</span>
                              {{ scope.row.away }}
                          </div>
                          </template>
                      </el-table-column>

                      <el-table-column key="shenfu" label="投注区" v-if="navIndex == 1">
                        <el-table-column label="让球" width="80" v-if="navIndex == 1">
                          <template slot-scope="scope">
                          <div class="ball_box">
                              <div class="ball_box_item">
                              {{ scope.row.odds[0].num || '0' }}
                              </div>
                          </div>
                          </template>
                        </el-table-column>
                          <el-table-column label="胜" width="80">
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[0].data[0], '胜平负')
                                          "
                                          @dblclick.native="shishiClick(scope.row,scope.row.odds[0].data[0])"
                                          :class="
                                              scope.row.odds[0].data[0].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[0].data[0].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="平" width="80">
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[0].data[1], '胜平负')
                                          "
                                          @dblclick.native="shishiClick(scope.row,scope.row.odds[0].data[1])"
                                          :class="
                                              scope.row.odds[0].data[1].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[0].data[1].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="负" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[0].data[2], '胜平负')
                                          "
                                          @dblclick.native="shishiClick(scope.row,scope.row.odds[0].data[2])"
                                          :class="
                                              scope.row.odds[0].data[2].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[0].data[2].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                      </el-table-column>
                      <el-table-column key="wanfa" label="玩法" width="120" v-else-if="navIndex == 2">
                        <template slot-scope="scope">
                          <div class="score_box">
                            <el-button v-if="scope.row.expandsStatus == 0" type="primary" @click="clickRowHandle(scope.row)">展开</el-button>
                            <el-button v-else @click="clickRowHandle(scope.row)">收起</el-button>
                          </div>
                        </template>
                      </el-table-column>
                      <!-- 总进球 -->
                      <el-table-column  key="zongjinqiu" label="投注区" v-else-if="navIndex == 3">
                          <el-table-column label="0" width="80">
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[1].data[0], '总进球')
                                          "
                                          @dblclick.native="shishiClick(scope.row,scope.row.odds[1].data[0])"
                                          :class="
                                              scope.row.odds[1].data[0].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[1].data[0].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="1" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box"  >
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[1].data[1], '总进球')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[1].data[1])"
                                          :class="
                                              scope.row.odds[1].data[1].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[1].data[1].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="2" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box" >
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[1].data[2], '总进球')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[1].data[2])"
                                          :class="
                                              scope.row.odds[1].data[2].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[1].data[2].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="3" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[1].data[3], '总进球')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[1].data[3])"
                                          :class="
                                              scope.row.odds[1].data[3].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[1].data[3].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="4" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[1].data[4], '总进球')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[1].data[4])"
                                          :class="
                                              scope.row.odds[1].data[4].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[1].data[4].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="5" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[1].data[5], '总进球')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[1].data[5])"
                                          :class="
                                              scope.row.odds[1].data[5].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[1].data[5].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="6" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box" >
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[1].data[6], '总进球')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[1].data[6])"
                                          :class="
                                              scope.row.odds[1].data[6].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[1].data[6].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="7+" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[1].data[7], '总进球')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[1].data[7])"
                                          :class="
                                              scope.row.odds[1].data[7].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[1].data[7].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                      </el-table-column>
                      <!-- 半全场 -->
                      <el-table-column  key="banchang" label="投注区" v-else-if="navIndex == 4">
                          <el-table-column label="胜-胜(3-3)" width="80">
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[2].data[0], '半全场')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.scope.row.odds[2].data[0])"
                                          :class="
                                              scope.row.odds[2].data[0].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[2].data[0].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="胜-平(3-1)" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[2].data[1], '半全场')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[2].data[1])"
                                          :class="
                                              scope.row.odds[2].data[1].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[2].data[1].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="胜-负(3-0)" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[2].data[2], '半全场')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[2].data[2])"
                                          :class="
                                              scope.row.odds[2].data[2].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[2].data[2].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="平-胜(1-3)" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[2].data[3], '半全场')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[2].data[3])"
                                          :class="
                                              scope.row.odds[2].data[3].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[2].data[3].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="平-平(1-1)" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[2].data[4], '半全场')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[2].data[4])"
                                          :class="
                                              scope.row.odds[2].data[4].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[2].data[4].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="平-负(1-0)" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[2].data[5], '半全场')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[2].data[5])"
                                          :class="
                                              scope.row.odds[2].data[5].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[2].data[5].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="负-胜(0-3)" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[2].data[6], '半全场')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[2].data[6])"
                                          :class="
                                              scope.row.odds[2].data[6].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[2].data[6].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="负-平(0-1)" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[2].data[7], '半全场')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[2].data[7])"
                                          :class="
                                              scope.row.odds[2].data[7].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[2].data[7].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="负-负(0-0)" width="80" >
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[2].data[8], '半全场')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[2].data[8])"
                                          :class="
                                              scope.row.odds[2].data[8].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[2].data[8].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                      </el-table-column>
                      <!-- 上下单双 -->
                      <el-table-column  key="shagnxia" label="投注区" v-else-if="navIndex == 5">
                          <el-table-column label="上单" width="80">
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[3].data[0], '上下单双')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[3].data[0])"
                                          :class="
                                              scope.row.odds[3].data[0].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[3].data[0].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="上双" width="80">
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[3].data[1], '上下单双')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[3].data[1])"
                                          :class="
                                              scope.row.odds[3].data[1].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[3].data[1].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="下单" width="80">
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[3].data[2], '上下单双')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[3].data[2])"
                                          :class="
                                              scope.row.odds[3].data[2].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[3].data[2].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                          <el-table-column label="下双" width="80">
                              <template slot-scope="scope">
                                  <div class="score_box">
                                      <el-button
                                          class="score_box_item cursor"
                                          @click="
                                              onSingle(scope.row, scope.row.odds[3].data[3], '上下单双')
                                          "
                                          @dblclick.native="shishiClick(scope.row, scope.row.odds[3].data[0])"
                                          :class="
                                              scope.row.odds[3].data[3].status == 1 ? 'single_f' : ''
                                          "
                                          >
                                          {{ scope.row.odds[3].data[3].value }}
                                      </el-button>
                                  </div>
                              </template>
                          </el-table-column>
                      </el-table-column>
                  </el-table>
              </div>
              <div v-show="navIndex == 6">
                <el-table
                  :data="tableData" 
                  border 
                  :height="tableHedr"
                  style="width: 100%"
                  >
                  <el-table-column
                          label="场"
                          type="index"
                          width="50">
                          <template slot-scope="scope">
                              <div>{{scope.row.id_str}}</div>
                          </template>
                      </el-table-column>
                  <el-table-column label="类型" width="80">
                      <template>
                          <div>足球</div>
                      </template>
                  </el-table-column>
                  <el-table-column label="状态" width="80">
                      <template>
                          <div>销售中</div>
                      </template>
                  </el-table-column>
                  <el-table-column label="赛事" width="160">
                      <template slot-scope="scope">
                          <div>{{ scope.row.comp }}</div>
                      </template>
                  </el-table-column>
                  <el-table-column label="时间" width="180">
                      <template slot-scope="scope">
                          <div>{{ scope.row.time }}</div>
                      </template>
                  </el-table-column>
                  <el-table-column prop="home" label="主队vs客队">
                    <template slot-scope="scope">
                    <div>
                        {{ scope.row.home }} <span class="vs_text">VS</span>
                        {{ scope.row.away }}
                    </div>
                    </template>
                  </el-table-column>

                  <el-table-column label="让球" width="80">
                    <template slot-scope="scope">
                    <div class="ball_box">
                        <div class="">
                        {{ scope.row.odds.num || '0' }}(球)
                        </div>
                    </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="胜" width="80">
                    <template slot-scope="scope">
                        <div class="score_box">
                            <el-button
                                class="score_box_item cursor"
                                @click="
                                    onSingle(scope.row, scope.row.odds.data[0], '胜')
                                "
                                @dblclick.native="shishiClick(scope.row,scope.row.odds.data[0])"
                                :class="
                                    scope.row.odds.data[0].status == 1 ? 'single_f' : ''
                                "
                                >
                                {{ scope.row.odds.data[0].value }}
                            </el-button>
                        </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="负" width="80" >
                    <template slot-scope="scope">
                        <div class="score_box">
                            <el-button
                                class="score_box_item cursor"
                                @click="
                                    onSingle(scope.row, scope.row.odds.data[1], '负')
                                "
                                @dblclick.native="shishiClick(scope.row,scope.row.odds.data[2])"
                                :class="
                                    scope.row.odds.data[1].status == 1 ? 'single_f' : ''
                                "
                                >
                                {{ scope.row.odds.data[1].value }}
                            </el-button>
                        </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
          </div>
          <!--底部 -->
        <div class="always_bottom">
          <div class="always_bottom_title df_c_s">
            <div class="left df_c">
              <div class="mr_15">
                已选 <span class="color_red">{{ sessionNum }}</span> 场
              </div>
              <div class="multiple">
                <div class="m_r_10">倍数:</div>
                <div class="m_r_10">
                  <el-input-number
                    size="mini"
                    v-model="dataNumber"
                    @change="valChange"
                    :min="1"
                    :max="50"
                    label="描述文字"
                  >
                  </el-input-number>
                </div>
                <div>[最高50倍]</div>
              </div>
            </div>
            <div class="right df_c">
              <div class="m_r_10">客户名称:</div>
              <div class="right_input m_r_10">
                <el-input
                  size="mini"
                  width="80px"
                  class="m_r_10"
                  v-model="customerName"
                  placeholder="请输入客户名称"
                ></el-input>
              </div>
              <div class="m_r_10">打印份数:</div>
              <div class="m_r_10">
                <el-input-number
                  size="mini"
                  v-model="printNum"
                  @change="handleChange"
                  :min="1"
                  :max="2"
                  label="描述文字"
                >
                </el-input-number>
              </div>
              <el-button 
              size="mini" 
              :disabled="price == 0"
              @click="onSubmit"
              type="danger">提交</el-button>
            </div>
          </div>
          <div class="always_bottom_way mt_15 df_s">
            <div class="left df">
              <div class="m_r_15">过关方式：</div>
              <div>
                <div class="way_radio">
                  <el-radio v-model="isComplex" label="1">自由过关</el-radio>
                  <!-- <el-radio
                    v-if="this.sellingNumber > 2"
                    v-model="isComplex"
                    label="2"
                    >M串N</el-radio> -->
                </div>
                <!--    自由过关 -->
                <div v-if="isComplex == 1">
                  <div class="single df" v-if="singleList.length > 0">
                    <div
                      class=""
                      v-for="(singleItem, singleIndex) in singleList"
                      :key="singleIndex"
                      @click="selectMake(singleItem, singleIndex)"
                    >
                      <div 
                          v-if="small <= singleIndex"
                          :class="singleItem.status == 1 ? 'select_item' : ''"
                          class="single_item m_r_15 cursor">
                              {{ singleItem.showVal }}
                          </div>
                  </div>
                  </div>
                </div>

                <div v-else>
                  <div class="single df" v-if="multipleList.length > 0">
                    <div
                      class="single_item m_r_15 cursor"
                      v-for="(multipleItem, multipleIndex) in multipleList"
                      :key="multipleIndex"
                      :class="multipleItem.status == 1 ? 'select_item' : ''"
                      @click="selectMultiple(multipleItem, multipleIndex)"
                    >
                      {{ multipleItem.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              注数：<span class="color_red">{{ note }}</span>注
            </div>
            <div class="right">
              <div>
                投注金额：<span class="color_red">{{ price }}</span
                >元
              </div>
              <div>
                理论最高奖金：<span class="color_red">
                  <!-- <span v-if="minPrice > 0">{{ minPrice }} ～</span> -->
                  {{
                    purchase_amount > 500000000 ? "5,000,000,00" : purchase_amount
                  }} </span
                >元
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="修改赔率"
      :visible.sync="shishiShow"
      width="380px"
      center>
        <div>
          <el-form ref="form" :model="oddsForm" label-width="80px">
            <el-form-item label="类型：">
              <div class="odds_key">{{ newOddsForm.key }}</div>
            </el-form-item>
            <el-form-item label="旧赔率：">
              <el-input v-model="oddsForm.oldOdds"
              :disabled="true"
              ></el-input>
            </el-form-item>
            <el-form-item label="新赔率：">
              <el-input type="Number" v-model="oddsForm.newOdds"></el-input>
            </el-form-item>
          </el-form>
        </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="m_r_15" @click="shishiShow = false">取 消</el-button>
        <el-button type="primary" @click="onUpdateOdds">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import headNav from "@/components/navMenu";
import {
  getRealtimebeidan,
  getCodingGain,
  getPreviousBd,
  contestLotterys,
  getBjdcIssue
} from "@/utils/api.js";
import footballPrint from "../../components/print/footballPrint.vue";
import {
  calcDescartes,
  deepfilterArr,
  splitArray,
  getNowTime
} from "@/components/package/unit.js";
import {
  minCal,
  calculate,
  getPay,
  sortdatalist,
} from "@/components/package/opt.js";
import multipleData from "@/components/package/multipleData.js";
import timeData from "./components/dataForm.js"
var time = null;  //  在这里定义time 为null
export default {
  components: {
    headNav,
  },
  props: {
    outerStyle:  {   // false 往期数据
      type: Boolean,
      default: true
    },   
    date: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      NavList: [
        {
          name: "胜平负",
          num: "1",
          gameType: "1",
        },
        {
          name: "单场比分",
          num: "2",
          gameType: "2",
        },
        {
          name: "总进球",
          num: "3",
          gameType: "3",
        },
        {
          name: "半全场胜平负",
          num: "4",
          gameType: "5",
        },
        {
          name: "上下盘单双数",
          num: "5",
          gameType: "4",
        },
        {
          name: "胜负过关",
          num: "6",
          gameType: "6",
        }
      ],
      gameType: 1,
      bonusPeriod: '', // 期数
      handNav: '0',
      navIndex: 1,
      beidanList: [],
      bifenList: [],
      tableData: [],
      printNum: 1, // 打印的份数
      customerName: "中国体育彩票", //  客户名称
      loseList: [], // 胜平负
      scoreList: [], // 比分的数据
      sellingList: [],
      lotteryList: [], // 筛选出选中的场次
      sellingNumber: 0, // 场次
      total: 0, // 选中的场次
      singleList: [], // 自由过关
      selectMakeList: [], // 选中的自由过关组合
      selectMultipleShow: false,
      multipleList: [], // M串N
      selectMakeList: [], // 选中M串Nde shi
      isComplex: "1", // 过关方式  1:自由过关  2.M串N
      multipleShow: true,
      dataNumber: 1, //  下单倍数
      notPerice: 2, //  订单单价，固定两元
      odds: 0.65, //  订单税值  北单：0.65  其他1
      optimizeList: [], //  金额数组
      note: 0, // 有多少注
      price: 0, // 金额
      minPrice: 0, // 最小金额
      purchase_amount: 0, // 最大金额
      isRemoval: false,
      minList: [],
      sendList: [],
      blendCode: 1, //1.足球 2.篮球
      target: {},
      small: 1,// 是否显示单关
      printShow: false,    // 是否显示打印
      shishiShow: false,
      maximumBonus: 0,
      oddsForm: {//更改新旧
        oldOdds: '',
        newOdds: ''
      },
      newOddsForm: {},
      expands: [],    // 要展开的行，数值的元素是row的key值
      dataId: '',
      dataKey: '',
      printForm: {},
      afterLottery: [],    // 提交的玩法数据结构
      reachType: '',
      selectedValue: '',
      loading: false,
      expands: [],
      currentYear: '',    // 当前年
      currentMonth: '',    // 当前月
      year: '',           // 选中的年
      month: '',          // 选中的月
      yearList: [],       // 年数据
      monthList: [],      // 月数据
      tableHedr: 0,
      periodsList: [],     // 期数集合
      sessionNum: 0
    };
  },
  mounted() {
    if(this.outerStyle) {
      this.getTableData();
    }else {
      this.getPreviousPeriodsForm()
    }
    this.customerName = localStorage.getItem('userName')

    this.$nextTick(() => {
     const el = this.$refs.beidan
      const height = el.offsetHeight
      this.tableHedr = height - 15
    })
  },
  methods: {
    // 年   
    yearChange(e) {
      if(e == this.currentYear) {
        this.month = this.currentMonth
        this.monthList = timeData.monthFormList.filter(item => {
          return item.val <= Number(this.currentMonth)
        })
      }else {
        this.monthList = timeData.monthFormList
        this.month = this.monthList[0].val
      }

      this.getBjdcIssueData()
    },
    // 月
    monthChange(e) {
      this.getBjdcIssueData()
    },
    // 期号
    periodChange(e) {
      this.getPeriodsForm()
    },
   
    getRowClass() {
      if (this.navIndex == 2) {
        return 'row-expand-code'
      }else {
        return 'row-expand-cover'
      }
    },
    getPreviousPeriodsForm() {
      const dt =  new Date();
      this.currentYear  = dt.getFullYear();
      this.currentMonth  = (dt.getMonth() + 1 + '').padStart(2, '0');
      this.year = this.currentYear
      this.month = this.currentMonth
      this.filterTimeList()
      this.getBjdcIssueData()
    },
    // 筛选年月数据
    filterTimeList() {
      this.yearList = timeData.yearFormList.filter(item => {
        return item.val <= this.currentYear
      })
      this.monthList = timeData.monthFormList.filter(item => {
        return item.val <= Number(this.currentMonth)
      })
    },
    getBjdcIssueData() {
      this.periodsList = []
      getBjdcIssue({
        year: this.year,
        month: this.month
      }).then(res => {
        this.periodsList = res.data
        this.bonusPeriod = this.periodsList[0]      // 设置默认值
        this.getPeriodsForm()
      })
    },
    
    getPeriodsForm() {
      this.loading = true
      let that = this;
      this.tableData = []
      getPreviousBd({
        issue: this.bonusPeriod,
        month: this.month,
        year: this.year
      }).then(res => {
        if (res.code == 200) {
          that.loading = false
          that.beidanList = res.data.beidanlist
          that.tableData = that.beidanList[this.handNav].info_data
          that.bifenList = res.data.beidansfgg
          that.tableData.forEach(tableItem => {
            that.$set(tableItem, 'expandsStatus', 1)
            this.expands.push(tableItem.id);
          })
        }else {
          that.loading = false
        }
      })

      getCodingGain({
        type: 2,
        print_versions: 1
      }).then(res => {
        if(res.code == 200) {
          that.printForm = res.data
        }
      })
    },
    clickRowHandle(row) {
      if (this.expands.includes(row.id)) {
        row.expandsStatus = 0
        this.expands = this.expands.filter(val => val !== row.id);
      } else {
        row.expandsStatus = 1
        this.expands.push(row.id);
      }
    },
    getTableData() {
        const that = this
      this.loading = true
      getRealtimebeidan({}).then((res) => {
        this.loading = false
        that.bonusPeriod = res.data.bonus_period
        that.beidanList = res.data.beidanlist
        that.tableData = that.beidanList[this.handNav].info_data
        that.bifenList = res.data.beidansfgg
        that.tableData.forEach(tableItem => {
          that.$set(tableItem, 'expandsStatus', 1)
          this.expands.push(tableItem.id);
        })
      });

      getCodingGain({
        type: 2,
        print_versions: 1
      }).then(res => {
        if(res.code == 200) {
          that.printForm = res.data
        }
      })
    },
    switchNavIndex(index) {
      this.gameType = this.NavList[Number(index) - 1].gameType
      this.navIndex = index;
      this.handNav = '0'
      if(this.navIndex != 6) {
        this.tableData = this.beidanList[this.handNav].info_data
      }else {
        this.tableData = this.bifenList[this.handNav].info_data
      }
      this.cleanBaskValue()
    },
    cleanBaskValue() {
      this.beidanList.forEach(beidanItem => {
					beidanItem.info_data.forEach(infoItem => {
          infoItem.odds.forEach(oddsItem => {
            oddsItem.data.forEach(item => {
              if (item.status == 1) {
                item.status = 0
              }
            })
          })
        })
      })
      this.bifenList.forEach(beidanItem => {
					beidanItem.info_data.forEach(infoItem => {
          infoItem.odds.data.forEach(item => {
              if (item.status == 1) {
                item.status = 0
              }
            })
        })
      })
      this.sellingNumber = 0
      this.sessionNum = 0
      this.dataNumber = 1
      this.printNum = 1
      this.target = []
      this.lotteryList = []
      this.sellingList = []
      this.purchase_amount = 0
      this.price = 0
      this.singleList = []
      this.multipleList = []
      this.note = 0
    },
    handleSelect(key) {
        this.handNav = key
        if(this.navIndex == 6) {
          this.tableData = this.bifenList[this.handNav].info_data
        }else {
          this.tableData = this.beidanList[this.handNav].info_data
        }
    },
     // 确定修改赔率
     async onUpdateOdds() {
      this.newOddsForm.value = this.oddsForm.newOdds
      this.shishiShow = false
      // 修改计算值的赔率   需要场次的id以及玩法的key进行判定
      this.sellingList.forEach(sellingItem => {
        if(sellingItem.id == this.dataId && this.dataKey == sellingItem.key) {
          sellingItem.value = this.oddsForm.newOdds
        }
      })
      await this.childclick(this.notPerice, this.odds, this.dataNumber)
    },
    async onSingle(dataItem, item, title) {
        clearTimeout(time);  //首先清除计时器
        time = setTimeout(async () => {
          if (item.status == 0) {
            let backFlag = this.isBackEnd(dataItem);
            console.log(this.sellingNumber);
            console.log(backFlag);
            if (this.sessionNum == 15 && !backFlag) {
              this.$message({
                type: "error",
                message: `最多只能选15场比赛！`,
              });
              return;
            }
            this.total = this.total + 1;
            // dataItem.selectNum = dataItem.selectNum + 1
            item.status = 1;
            await this.setSelling(dataItem, item, title);
          } else {
            this.total = this.total - 1;
            // dataItem.selectNum = dataItem.selectNum - 1
            item.status = 0;
            let sellingIndex = 0;
            this.sellingList.forEach((sellingItem, index) => {
              if (
                sellingItem.value == item.value &&
                sellingItem.id == dataItem.id &&
                sellingItem.title == title
              ) {
                sellingIndex = index;
              }
            });
            this.deleteSelling(sellingIndex);
          }
        }, 300);   //大概时间300ms
    },
    // 取消选中状态
    async deleteSelling(index) {
      this.sellingList.splice(index, 1);
      await this.bySelling();
      await this.childclick();
    },
       /* 判断是否是已经选过的 */
    isBackEnd(codeItem) {
      let flag = false;
      this.sellingList.forEach((sellingItem) => {
        if (sellingItem.id == codeItem.id) {
          flag = true;
        }
      });
      return flag;
    },
    // 双击
    shishiClick(dataItem, item){
      if(this.outerStyle == true) {
        return
      }
      clearTimeout(time); //首先清除计时器
      this.shishiShow = true;
      this.oddsForm.oldOdds = item.value;
      this.oddsForm.newOdds = item.value;
      this.newOddsForm = item;

      this.dataId = dataItem.id
      this.dataKey = item.key
    },
    async setSelling(dataItem, item, title) {
        let num = ''
        if(this.navIndex == 6) {
          num = dataItem.odds.num;
        }else {
          num = dataItem.odds[0].num;
        }
        let data = {
            id: dataItem.id, //id
            comp: dataItem.comp,
            home: dataItem.home,
            away: dataItem.away,
            issue: dataItem.issue,
            issue_num: dataItem.id_str, //序号
            match_time: dataItem.match_time, //比赛时间
            title: title,
            key: item.key,
            value: item.value,
            week: dataItem.week,
            num: num
        }
      this.sellingList.push(data);
      await this.bySelling();
      await this.childclick();
    },
    // 统一调用算法入口
    async childclick() {
      await this.setBeidanLists()
      // 初始化多串选择
      this.isComplex = "1";
      this.selectMakeList = [];
      let makeItem = {};
      // this.lotteryList.sort(sortdatalist('id'))

      if(this.navIndex == 2) {
        if(this.sellingNumber > 3) {
          this.sellingNumber = 3
        }
      }

      if(this.navIndex == 3) {
        if(this.sellingNumber > 6) {
          this.sellingNumber = 6
        }
      }
      if(this.navIndex == 4) {
        if(this.sellingNumber > 6) {
          this.sellingNumber = 6
        }
      }

      if(this.navIndex == 5) {
        if(this.sellingNumber > 6) {
          this.sellingNumber = 6
        }
      }

      // 是否选中比赛
      if (this.sellingNumber == 0) {
        this.singleList = [];
        this.purchase_amount = 0
        this.price = 0
        this.note = 0
      } else {
        await this.countNumber(this.sellingNumber);
        this.$nextTick(() => {
          this.singleList[this.sellingNumber - 1].status = 1;
        });
        //默认设置一个多串过关的选项
        makeItem = this.singleList[this.sellingNumber - 1];
        this.selectMakeList.push(makeItem);
        if (this.sellingNumber > 2) {
          this.setMultiple(this.sellingNumber);
        }
        await this.countMinCal();
        await this.countSingle();
        let scoreGoal = await this.filterGoal(this.sellingList)
        if(scoreGoal) {
            this.small = 0
        }else {
            this.small = 1
        }
        // if(this.singleList.length > 1) {
          this.countAmount(this.notPerice, this.odds, this.dataNumber);
          this.setTimeoutList();
        // }
      }
    },
     /* 计算值 */
     async setTimeoutList() {
      this.isRemoval = true;
      let arrangeList = [];
      let filteList = [];
      this.selectMakeList.forEach((seleItem) => {
        let count = minCal(this.sellingList, seleItem.value, "ang");
        arrangeList.push(count);
      });

      this.selectMakeList.forEach((seleItem) => {
        let count = minCal(this.sellingList, seleItem.value, "fil");
        filteList.push(count);
      });
      this.filteList = filteList;
      this.arrangeList = arrangeList;
      this.setFilteCode();
    },
    setFilteCode() {
      let codeList = [];
      let lotteryLen = this.lotteryList.length;
      this.filteList.forEach((arrItem, arrIndex) => {
        arrItem.forEach((codeItem, codeIndex) => {
          let data = {
            mode: lotteryLen == 1 ? "单关" : `${arrItem[0].length}串1`,
            multiple: this.dataNumber,
            bonus: this.setMoney(arrIndex, codeIndex, "filte"),
            odds: codeItem,
          };
          codeList.push(data);
        });
      });
      this.afterLottery = codeList;
    },
    ifType(type) {
				let flat = false
				if (type instanceof Array) {
					flat = true
				} else {
					flat = false
				}
				return flat
			},
    async countAmount(notPerice, odds, number) {
      this.optimizeList = [];
      let selectLen = this.selectMakeList.length;
      let newMoney = 0;
      let newNote = 0;
      let minList = [];
      await this.selectMakeList.forEach((seleItem) => {
        let count = [];
        this.minList.forEach((minItem) => {
          if (minItem.key == seleItem.value) {
            count = minItem.list;
          }
        });
        this.optimizeList.push(count);
        newNote = newNote + count.length;
        let setMoney = 0;
        let newMoneyArr = [];
        if (seleItem.name == "单关") {
          let dan = [];
          count.forEach((counts) => {
            if (this.ifType(counts)) {
              dan.push(this.maxMoney(counts));
            } else {
              dan.push([counts]);
            }
          });
          let code = 0;
          dan.forEach((item) => {
            let money = item * notPerice;
            code = money + code;
          });
          setMoney = code * number * odds;
          if (count.length > 1) {
            // 单关多场比赛需要计算最低预测奖金
            let minMoneys = [];
            count.forEach((countItem) => {
              let money = 1;
              countItem.forEach((sonItem) => {
                money = sonItem * notPerice * number * odds;
                minMoneys.push(money.toFixed(2));
              });
            });
            this.minPrice = this.minMoney(minMoneys);
          } else {
            this.minPrice = 0;
          }
        } else {
          if (count.length == 1) {
            count.forEach((counts) => {
              let money = 1;
              counts.forEach((item) => {
                money = item * money;
              });
              setMoney = money * notPerice * number * odds;
            });
          } else {
            count.forEach((counts) => {
              let card = 1;
              counts.forEach((item) => {
                card = card * item;
              });
              /* 获取最小金额 */
              let minPrice = card * notPerice * number * odds;
              minList.push(minPrice);
              setMoney = setMoney + card;
            });
            setMoney = setMoney * notPerice * number * odds;
          }
        }
        newMoney = newMoney + setMoney;
      });

      this.note = newNote; // 多少注
      this.price = this.note * this.notPerice * number; // 多少钱

      if (this.multipleShow && minList.length > 1) {
        this.minPrice = this.minMoney(minList).toFixed(2);
      }
      // 去计算奖金
      this.countBeidan(notPerice, odds, number);
    },
     // 计算奖金
     setMoney(arrIndex, codeIndex, type) {
      let lotteryLen = this.lotteryList.length;
      let codeNum = 0;
      if (lotteryLen > 1) {
        let money = 1;
        this.optimizeList[arrIndex].forEach((optimizeItem, index) => {
          if (index == codeIndex) {
            optimizeItem.forEach((item) => {
              money = item * money;
            });
          }
        });
        let code = "";
        if (type == "data") {
          code = money.toFixed(2) * this.notPerice;
        } else {
          code =
            money.toFixed(2) * this.notPerice * this.odds * this.dataNumber;
        }

        codeNum = code.toFixed(2);
      } else {
        codeNum = (
          this.optimizeList[0][codeIndex] *
          this.notPerice *
          this.odds *
          this.dataNumber
        ).toFixed(2);
      }
      return codeNum;
    },
      /* 计算预测奖金 */
      async countBeidan(notPerice, odds, number) {
      this.isRemoval = false;
      let maximumBonus = 0; // 最大金额
      let countMaxs = [];
      const numList = [];
      this.selectMakeList.forEach((seleItem) => {
        this.sendList.forEach((sendItem) => {
          if (seleItem.value == sendItem.key) {
            let newData = {
              key: sendItem.key,
              num: sendItem.list[1],
            };
            numList.push(newData);
            countMaxs.push(sendItem.list[1]);
          }
        });
      });
      /* 统计注数和最大金额 */
      countMaxs.forEach((maxItem) => {
        // this.note += sendItem[0]
        maximumBonus += maxItem;
      });
      this.purchase_amount = (
        maximumBonus.toFixed(2) *
        notPerice *
        odds *
        number
      ).toFixed(2);
      // await this.purchaseRule(this.lotteryList.length, this.purchase_amount)
      await this.setSignList(notPerice, odds, number);
    },
    async setSignList(notPerice, odds, number) {
      let list = await this.maximum();
      let arrangeList = [];
      this.selectMakeList.forEach((seleItem) => {
        let count = this.countMoney(list, seleItem.value);
        arrangeList.push(count);
      });
      splitArray(arrangeList, notPerice, odds, number, "bask", this);
    },
     /* 过滤最大值 */
     maxMoney(moneyList) {
      let MaxArrp = Math.max(...moneyList);
      return MaxArrp;
    },
    countMoney(a, min) {
      return deepfilterArr(a, min, this.isRemoval);
    },
    /* 过滤最小值 */
    minMoney(moneyList) {
      let MinArrp = Math.min(...moneyList);
      return MinArrp;
    },
     /* 判断是否开启单关 */
     filterGoal(list) {
        let codeStu = true
        list.forEach(item => {
            if (item.title == '让胜平负') {
                codeStu = false
            }
            if(item.title == '胜平负') {
                if(item.single == 1) {
                    codeStu = false
                }
            }
        })
        return codeStu
    },
     /* 是否有多串数据 */
     setMultiple(num) {
      this.multipleList = [];
      if (this.multipleShow) {
        let list = multipleData.multipleList;
        list.map((item) => {
          if (item.len == num) {
            this.multipleLen = item.len;
            this.multipleList = item.list;
          }
        });
      }
    },
    countMinCal() {
      let minList = [];
      this.singleList.forEach((leItem) => {
        let count = minCal(this.sellingList, leItem.value);
        let data = {
          key: leItem.value,
          list: count,
        };
        minList.push(data);
      });
      this.minList = minList;
    },
    /* 先统计所有的组合和最高奖金 */
    async countSingle() {
      let codeList = await this.retoolLotter();
      let calList = [];
      this.singleList.forEach((leItem) => {
        let count = calculate(codeList, leItem.value);
        let data = {
          key: leItem.value,
          list: count,
        };
        calList.push(data);
      });
      let sendList = [];
      calList.forEach((calItem) => {
        let send = getPay(calItem.list);
        let data = {
          key: calItem.key,
          list: send,
        };
        sendList.push(data);
      });
      this.sendList = sendList;
    },
     /* 重组数组 */
     retoolLotter() {
      let newArr = [];
      this.lotteryList.forEach((lotterItem) => {
        let formData = {
          id: lotterItem.id,
          code: lotterItem.week,
          item: lotterItem.issue_num,
          matchCode: lotterItem.week + lotterItem.issue_num,
          comp: lotterItem.comp,
          home: lotterItem.home,
          away: lotterItem.away,
          pl: [],
          plv: [],
        };
        this.sellingList.forEach((sellItem) => {
          if (lotterItem.id == sellItem.id) {
            formData.pl.push(sellItem.value);
          }
        });
        newArr.push(formData);
      });
      return newArr;
    },
    /* 单串 */
    async countNumber(val) {
      this.singleList = [];
      if (val > 0) {
        for (let i = 0; i < val; i++) {
          let data = {
            name: "",
            value: 0,
            status: "",
          };
          if (i == 0) {
            data.name = "单关";
            data.value = 1;
            data.status = 0;
            data.showVal = "单关"
          } else {
            data.name = i + 1 + "串" + "1";
            data.value = i + 1;
            data.status = 0;
            data.showVal = `${i + 1}关`
          }
          this.singleList.push(data);
        }
      }
      /* 计算金额 */
    },
    setBeidanLists() {
        let newArr = []
        //先遍历取出搜索的key（因为要根据不同的key生成数组）
        let keys = this.sellingList.map((val) => {
            return val.id;
        });
        //数组去重，这样就得到了一共有多少个key
        keys = [...new Set(keys)];
        //声明一个target来装数组
        let target = {};
        //将target对象的每一项设成数组格式
        keys.forEach((val) => {
            target[val] = [];
        });
        //如果key值相等，则push到同一个数组
        this.sellingList.map((val) => {
            keys.some((value) => {
                if (val.id == value) {
                    target[value].push(val);
                    return;
                }
            });
        });
        this.target = target
    },
    /* 统计选中的值 */
    bySelling() {
      var newArr = [];
      let obj = {};
      for (let i = 0; i < this.sellingList.length; i++) {
        //将arr[i].id作为对象属性进行判断
        if (!obj[this.sellingList[i].id]) {
          newArr.push(this.sellingList[i]);
          obj[this.sellingList[i].id] = true;
        }
      }
      this.lotteryList = newArr;
      this.sellingNumber = newArr.length;
      this.sessionNum = newArr.length;
      // this.isCommit()
    },
    // 倍数
    async valChange(e) {
        await this.countAmount(this.notPerice, this.odds, e)
    },
    /* 计算最高奖金 */
    maximum() {
      let newArr = [];
      this.lotteryList.forEach((lotterItem) => {
        // dataArr.push(item)
        let arr = this.target[lotterItem.id];
        let dataArr = [];
        arr.forEach((item) => {
          let val = item.name + "," + item.value;
          if (item.num) {
            val = val + "," + item.num;
          }
          dataArr.push(val);
        });
        newArr.push(dataArr);
      });
      return newArr;
    },
    selectMake(item, index) {
      if (item.status == 0) {
        item.status = 1;
        this.selectMakeList.push(item);
      } else {
        if (this.selectMakeList.length == 1 && item.status == 1) {
          this.$message.error("至少选择一种过关方式");
        } else {
          item.status = 0;
          this.selectMakeList.splice(index, 1);
        }
      }
      this.selectMakeList = this.singleList.filter((item) => {
        return item.status == 1;
      });
      this.countAmount(this.notPerice, this.odds, this.dataNumber);
      this.setTimeoutList();
    },
    selectMultiple() {

    },
    async onSubmit() {
      await this.setFilteCode()
      this.reachType = '自由过关'
      let makeTest = []
      this.selectMakeList.forEach(makeItem => {
        makeTest.push(makeItem.name)
      })
      this.selectedValue = makeTest.join(',')

      const lotteryProceeds = this.price * 0.21
      const printParameter = {
        type: 3,
        game_type: this.gameType,
        random_number: this.printForm.random_number,              // 顶部编码1
        random_numberT: this.printForm.random_numberT,            // 顶部编码2
        random_numberS: this.printForm.random_numberS,
        random_numberF: this.printForm.random_numberF,
        random_numbeD: this.printForm.random_numbeD,
        randomString: this.printForm.randomString,                // 顶部编码3
        selectedValue: this.selectedValue,                         // 过关方式
        multiple: this.dataNumber,                                // 倍数
        Betamount: this.price,                                    // 合计金额
        combinationboun: this.purchase_amount,            // 最高奖金
        combinationCount: this.note,           // 注数
        lotteryProceeds,                   // 公益贡献金额
        generatedNumber: this.printForm.generatedNumber,
        currentDate: getNowTime('base'),
        lottery_add: this.printForm.lottery_add,
        order_time: getNowTime(),
        print_time: getNowTime(),
        bet_money: this.price,
        customer_name: this.customerName,
        reach_type: this.reachType,
        note: this.note,
        Clearance_mode: this.selectedValue,
        lottery: JSON.stringify(this.sellingList),
        order_status: 2,
        after_lottery: JSON.stringify(this.afterLottery),
        issue: this.bonusPeriod
      }
      this.loading = true
      contestLotterys(printParameter).then((res) => {
      this.loading = false
        if(res.code == 200) {
            this.$message({
                type: "success",
                message: res.msg,
            });
            this.switchNavIndex(this.navIndex)
        }
      })

      getCodingGain({
        type: 2,
        print_versions: 1
      }).then(res => {
        if(res.code == 200) {
          this.printForm = res.data
        }
      })
    },
     // 打印
     handleChange() {},
  }
};
</script>


<style scoped lang="less">
/deep/ .el-table__expanded-cell {
  padding: 0 !important;
}
@import url("../../assets/css/always.css");

.single_f {
  background: #f53629 !important;
  color: #fff !important;
}
/deep/ .el-button {
  margin-left: 0 !important;
  // border-radius: 0 !important;
}

/deep/ .el-table .row-expand-cover .cell .el-table__expand-icon {
	display: none;
}

.content_table {
  height: calc(100% - 260px);
  overflow-y: auto;
}


.gird_item {
  border-radius: 0 !important;
}



.beidan_content {
  height: calc(100% - 70px);
}

.select_code {
  width: 120px;
  margin-left: 35px;
}
</style>